<template>
    <div class="container pt-4">
        <div class="row">
            <div class="mx-auto col-lg-4 col-md-6 col-sm-12">
                <div class="text-center p-2">
                    <h3>{{ $t('auth.title') }}</h3>
                </div>

                <form @submit.prevent="handleOnSubmit">
                    <div class="form-group">
                        <label for="account">{{ $t('auth.name') }}</label>
                        <input v-model="formData.account" name="account" id="account" class="form-control"
                               v-bind:class="{ 'is-invalid': errors.account}"/>
                        <div v-if="errors.account" class="invalid-feedback">{{ $t('errors.' + errors.account) }}</div>
                    </div>

                    <div class="form-group">
                        <label for="password">{{ $t('auth.password') }}</label>
                        <input v-model="formData.password" type="password" id="password" name="password" class="form-control"
                               v-bind:class="{ 'is-invalid': errors.password}"/>
                        <div v-if="errors.password" class="invalid-feedback">{{ $t('errors.' + errors.password) }}</div>
                    </div>

                    <div class="text-center p-2">
                        <button type="submit" :disabled="submitBtnDisabled()" class="btn btn-primary">{{ $t('auth.login') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import adminService from './../services/AdminService';

    export default {
        name: 'auth-login',

        mounted() {

        },

        data() {
            return {
                errors: this.getDefaultFields(),
                formData: this.getDefaultFields(),

                submitted: false,
            }
        },

        methods: {
            getDefaultFields() {
                return {
                    account: '',
                    password: '',
                }
            },

            submitBtnDisabled() {
                return this.submitted;
            },

            async handleOnSubmit() {
                this.errors = this.getDefaultFields();

                try {
                    this.submitted = true;
                    const result = await adminService.login(this.formData.account, this.formData.password);
                    adminService.setLogin(result.data);
                    this.$router.go();
                } catch (err) {
                    err.response.data.errors.forEach(errorItem => {
                        if (errorItem.field) {
                            this.errors[errorItem.field] = errorItem.message[0];
                        }
                    });
                } finally {
                    this.submitted = false;
                }
            }
        }
    }
</script>

<style>

</style>
