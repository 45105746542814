// require('./bootstrap');

import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import AdminModule from './modules/AdminModule.vue';
import Notifications from 'vue-notification'

import routes from './routes.js';
import { i18n } from './translation.js';
import AuthUtil from './utils/AuthUtil';
import config from '../config';

Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VueRouter);

moment.tz.setDefault('Asia/Singapore');
Vue.use(VueMoment, { moment });

Vue.component('admin-module', AdminModule.default);

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach(async (to, from, next) => {
    if ((to.meta !== undefined) && (to.meta !== null)) {
        const adminRoles = to.meta['checkedAdminRole'];
        
        if (Array.isArray(adminRoles)) {
            const adminInfo = (await AuthUtil.getAuth());
            const arrAdminInfo = JSON.parse(adminInfo);
            const role_number = Number(arrAdminInfo.role);
            if (typeof role_number === 'number') {
                // 各画面の権限チェック
                if (adminRoles.indexOf(role_number) < 0) {
                    // 管理者権限チェックによりはじかれた場合はエラーページに遷移させる
                    return next({ path: '/error' });
                }
            }
         }
    }
    return next();
});


const app = new Vue({
    router,
    i18n,
    render (h) {
        return h(AdminModule)
    }
}).$mount('#app');
