<template>
    <div>
        <div class="title">{{ $t('plans.list.title') }}</div>
        <div class="sync-button">
            <button @click="handelClickSyncButton" class="btn btn-primary">Sync</button>
        </div>
        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col">{{ $t('plans.id')}}</th>
                <th scope="col">{{ $t('plans.name') }}</th>
                <th scope="col">{{ $t('plans.frequency') }}</th>
                <th scope="col">{{ $t('plans.currency')}}</th>
                <th scope="col">{{ $t('plans.price') }}</th>
                <th scope="col">{{ $t('plans.description')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.frequency }}</td>
                <td>{{ item.currency }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.description }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import PayPalService from "../services/PayPalService";

    export default {
        name: 'plans-of-pay-pal',

        data() {
            return {
                list: []
            }
        },

        created() {
            this.fetchPayPalPlans();
        },

        methods: {
            async fetchPayPalPlans() {
                const result = await PayPalService.getSubscribersList();
                console.log(result.data, 'list');
                this.setListArray(result.data);
            },

            async handelClickSyncButton() {
                await PayPalService.getListOfPlans();
                this.fetchPayPalPlans();
            },

            setListArray (data = []) {
                this.list = [];
                for (let i in data) {
                    if (data.hasOwnProperty(i) && i !== 'meta') {
                        this.list.push(data[i]);
                    }
                }
            },

        }
    }
</script>

<style>
    .sync-button {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 15px;
    }
    button {

    }
</style>
