import ServerRequestUtil from './../utils/ServerRequestUtil';

export default new class {
    constructor() {
    }
    async getNewsDataList(params) {
        return  ServerRequestUtil.post('api/admin/news/getNewsDataList', {...params});
    }

    async getNewsDataDetail(id) {
        return  ServerRequestUtil.post('api/admin/news/getNewsDataDetail', {id: id});
    }

    async updateNewsData(params) {
        return  ServerRequestUtil.post('api/admin/news/updateNewsData', {...params});
    }

    async addNewsData(params) {
        return  ServerRequestUtil.post('api/admin/news/addNewsData', {...params});
    }
}