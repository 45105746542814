import ServerRequestUtil from '../utils/ServerRequestUtil';

export default new class {

    /** CSVアップロード */
    postCsv(data) {
        let formData = new FormData();
        for(let key in data) {
            if(data.hasOwnProperty(key)){
                formData.append(key, data[key]);
            }
        }
        return ServerRequestUtil.post("/api/admin/forthingcontracts/post-csv", formData);
    }

    /** キャンセル */
    cancel() {
        return ServerRequestUtil.put("/api/admin/forthingcontracts/cancel");
    }

    /** フォルシング契約処理実行 */
    executeForthingContracts() {
        return ServerRequestUtil.put("/api/admin/forthingcontracts/execution");
    }

}
