import ServerRequestUtil from './../utils/ServerRequestUtil';

export default new class {

    constructor() {
        this.STATUSES = {
            NOT_UPLOADED: '0',
            UPLOADED: '1',
            APPROVED: '2',
            REJECTED: '3',
            RE_UPLOADED: '4',
        };
    }

    getList(params) {
        return ServerRequestUtil.get('/api/admin/kyc', {...params});
    }

    export(params) {
        return ServerRequestUtil.get('/api/admin/kyc/export', {...params});
    }

    /**
     * ステータス更新
     *
     * @param id
     * @param params
     * @returns Object
     */
    update(id, params) {
        params.id = id;
        return ServerRequestUtil.put('/api/admin/kyc/status', {...params});
    }
}
