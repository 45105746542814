<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-9">
                <div class="row">
                    <!-- List of users -->
                    <div class="title col-lg-2">{{ $t('user.list.title') }}</div>
                    <!-- /List of users -->
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-row">
                            <!-- Email / Force Club ID -->
                            <div class="form-group col-lg-4">
                                <label>{{ $t('user.account') }}</label>
                                <input type="text" class="form-control" v-model="filter.email_or_force_club_id">
                            </div>
                            <!-- /Email / Force Club ID -->
                            <div class="form-group col-lg-2 offset-lg-5 d-flex align-items-end">
                                <!-- AND OR ラジオボタン -->
                                <div class="btn-group btn-group-toggle">
                                    <label class="btn" v-bind:class="{ 'btn-primary': filter.and_search, 'btn-light': !(filter.and_search) }"
                                        @click="handleOnclickJoin(1)">
                                        <input type="radio" name="and_search" v-bind:checked="filter.and_search" autocomplete="off"/>AND
                                    </label>
                                    <label class="btn" v-bind:class="{ 'btn-primary': !(filter.and_search), 'btn-light': filter.and_search }"
                                        @click="handleOnclickJoin(0)">
                                        <input type="radio" name="and_search" v-bind:checked="!(filter.and_search)" autocomplete="off" />OR
                                    </label>
                                </div>
                                <!-- /AND OR ラジオボタン -->
                            </div>
                        </div>
                    </div>
                    <!-- 資格停止者で絞り込むボタン -->
                    <div v-if="showMembershipSuspensionNarrowdownButton" class="col-lg-3 offset-1 pt-4 mt-2">
                        <a @click="handleOnClickMembershipSuspensionNarrowdownButton()" class="btn btn-primary">資格停止者で絞り込む</a>
                    </div>
                    <!-- /資格停止者で絞り込むボタン -->

                    <!-- すべてを表示するボタン -->
                    <div v-if="showFullDisplayButton" class="col-lg-3 offset-1 pt-4 mt-2">
                        <a @click="handleOnClickFullDisplayButton()" class="btn btn-primary">すべてを表示する</a>
                    </div>
                    <!-- /すべてを表示するボタン -->

                    <!-- CSV Export -->
                    <div class="col-lg-2 pt-4 mt-2 text-right">
                        <a @click="handleOnClickDownloadCsv()" download="users.csv" class="btn btn-primary">{{ $t('exportCsv') }}</a>
                    </div>
                    <!-- /CSV Export -->
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <!-- Search -->
                        <filter-bar :search="handleOnClickSearchButton">
                        <!-- /Search -->
                            <!-- First Name -->
                            <div class="form-group col-lg-4">
                                <label>{{ $t('user.first') }}</label>
                                <input type="text" class="form-control" v-model.lazy="filter.first_name">
                            </div>
                            <!-- /First Name -->
                            <!-- Family Name -->
                            <div class="form-group col-lg-4">
                                <label>{{ $t('user.family') }}</label>
                                <input type="text" class="form-control" v-model.lazy="filter.last_name">
                            </div>
                            <!-- /Family Name -->
                        </filter-bar>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-12">
                        <!-- Display Items -->
                        <div class="form-group col-lg-3 offset- row float-right pr-0">
                            <div class="col-lg-6 display-item-label">
                                <label>{{ $t('user.displayItem') }}</label>
                            </div>
                            <div class="col-lg-6 display-item">
                                <select class="form-control" v-model="pagination.per_page" v-on:change="handleOnClickDisplayItemsSelect()">
                                    <option v-for="option in displayItems" :value="option.value">{{ option.name }}</option>
                                </select>
                            </div>
                        </div>
                        <!-- /Display Items -->
                        <!-- pagination -->
                        <div class="form-group float-right">
                            <paginate :pagination="pagination" @change-page="search" v-if="pagination.total"></paginate>
                        </div>
                        <!-- /pagination -->
                    </div>
                </div>
                <!-- リストテーブル -->
                <div class="list-table">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col" class="filter sticky-content" @click="handleOnClickOrder('external_user_id')">
                                {{ $t('user.details.email') }}
                                <div class="block-with-caret">
                                    <div class="arrow-up"   v-if="checkOrder('external_user_id', 'asc')"></div>
                                    <div class="arrow-down" v-if="checkOrder('external_user_id', 'desc')"></div>
                                </div>
                            </th>
                            <th scope="col" class="filter sticky-content" @click="handleOnClickOrder('first_name')">
                                {{ $t('user.first') }}
                                <div class="block-with-caret">
                                    <div class="arrow-up"   v-if="checkOrder('first_name', 'asc')"></div>
                                    <div class="arrow-down" v-if="checkOrder('first_name', 'desc')"></div>
                                </div>
                            </th>
                            <th scope="col" class="filter sticky-content" @click="handleOnClickOrder('last_name')">
                                {{ $t('user.family') }}
                                <div class="block-with-caret">
                                    <div class="arrow-up"   v-if="checkOrder('last_name', 'asc')"></div>
                                    <div class="arrow-down" v-if="checkOrder('last_name', 'desc')"></div>
                                </div>
                            </th>
                            <th scope="col" class="filter sticky-content" @click="handleOnClickOrder('member_type_id')">
                                {{ $t('user.memberType') }}
                                <div class="block-with-caret">
                                    <div class="arrow-up"   v-if="checkOrder('force_club_id', 'asc')"></div>
                                    <div class="arrow-down" v-if="checkOrder('force_club_id', 'desc')"></div>
                                </div>
                            </th>
                            <th scope="col" class="filter sticky-content" @click="handleOnClickOrder('force_club_id')">
                                {{ $t('user.forceClubId') }}
                                <div class="block-with-caret">
                                    <div class="arrow-up"   v-if="checkOrder('force_club_id', 'asc')"></div>
                                    <div class="arrow-down" v-if="checkOrder('force_club_id', 'desc')"></div>
                                </div>
                            </th>
                            <th scope="col" class="filter sticky-content" @click="handleOnClickOrder('status')">
                                {{ $t('kyc.title') }}
                                <div class="block-with-caret">
                                    <div class="arrow-up"   v-if="checkOrder('status', 'asc')"></div>
                                    <div class="arrow-down" v-if="checkOrder('status', 'desc')"></div>
                                </div>
                            </th>
                            <th scope="col" class="filter sticky-content" @click="handleOnClickOrder('force_club_activation')">
                                {{ $t('user.details.forceTabActivation') }}
                                <div class="block-with-caret">
                                    <div class="arrow-up"   v-if="checkOrder('force_club_activation', 'asc')"></div>
                                    <div class="arrow-down" v-if="checkOrder('force_club_activation', 'desc')"></div>
                                </div>
                            </th>
                            <th scope="col" class="filter sticky-content" @click="handleOnClickOrder('google2sa_active')">
                                {{ $t('user.details.google2saActive') }}
                                <div class="block-with-caret">
                                    <div class="arrow-up"   v-if="checkOrder('google2sa_active', 'asc')"></div>
                                    <div class="arrow-down" v-if="checkOrder('google2sa_active', 'desc')"></div>
                                </div>
                            </th>
                            <th scope="col" class="filter sticky-content" @click="handleOnClickOrder('forthing_procedure')">
                                {{ $t('forthing.procedure') }}
                                <div class="block-with-caret">
                                    <div class="arrow-up"   v-if="checkOrder('forthing_procedure', 'asc')"></div>
                                    <div class="arrow-down" v-if="checkOrder('forthing_procedure', 'desc')"></div>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <!-- リストアイテム -->
                        <tbody>
                        <tr v-for="item in list" :class="setListItemClass(item)" @mouseover="activeItem=item" @mouseout="activeItem=''" @click="showDetails(item); setSelectedUserDetailsState(true); selectedItem=item">
                            <th  scope="row">{{ item.email }}</th>
                            <td>{{ item.user_data.first_name }}</td>
                            <td>{{ item.user_data.last_name }}</td>
                            <td>{{ $t(`user.memberTypeId[${parseMemberType(item)}]`) }}</td>
                            <td>{{ item.force_club_id }}</td>
                            <td>{{ $t(`kyc.statuses[${parseKycStatus(item)}]`) }}</td>
                            <td>{{ $t(`force.statuses[${parseForceStatus(item)}]`) }}</td>
                            <td>{{ $t(`google2sa.statuses[${item.google2sa_active ? 1: 0}]`) }}</td>
                            <td>{{ $t(`forthing.statuses.${parseForthingStatus(item)}`) }}</td>
                        </tr>
                        </tbody>
                        <!-- /リストアイテム -->
                    </table>
                </div>
                <!-- /リストテーブル -->
            </div>

            <!-- User Details -->
            <div class="detail-block col-lg-3">
                <div>
                    <h5 class="mt-2">{{ $t('user.details.title') }}</h5>

                    <!-- 会員資格停止中表示エリア(リストアイテムが選択された&&会員資格停止テーブルの状態が資格停止中の場合に表示する) -->
                    <div v-if="isSelectedUserDetails && details.is_enabled">

                        <!-- 会員資格停止中 -->
                        <p class="pt-1 pb-1 bg-red h5 text-white text-center">会員資格停止中</p>
                        <!-- /会員資格停止中 -->

                        <!-- 資格停止日時 -->
                        <p class="mb-0 font-weight-bold text-left">資格停止日時</p>
                        <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" :value="details.suspended_at" disabled>
                        </div>
                        <!-- /資格停止日時 -->

                        <!-- 資格停止理由 -->
                        <p class="mb-0 font-weight-bold text-left">資格停止理由</p>
                        <div class= "mx-auto user-details-text">
                        <textarea type="text" class="form-control mb-2" rows="3" :value="details.comment" disabled></textarea>
                        </div>
                        <!-- /資格停止理由 -->

                    </div>
                    <!-- /会員資格停止中表示エリア:会員資格停止テーブルの状態が資格停止中の場合に表示する -->

                    <!-- ユーザー情報,User Info -->
                    <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.userInfo') }}</p>
                    <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.email')}: ${details.email}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.aubeId')}: ${details.external_user_id}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.id')}: ${details.id}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.first')}: ${details.first_name}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.family')}: ${details.last_name}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.birthDate')}: ${getDate(details.birth_date)}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.memberStatus')}: ${details.member_status}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.memberType')}: ${details.member_type}`" disabled>
                    </div>
                    <!-- /ユーザー情報,User Info -->

                    <!-- サブアカウント情報,Sub Account Info -->
                    <div v-if="(details.sub_accounts.length !== 0)">
                        <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.subAccountInfo') }}</p>
                        <div v-for="item in details.sub_accounts" v-bind:key="item.id">
                        <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.aubeId')}: ${item.external_user_id}`" disabled>
                        </div>
                        </div>
                    </div>
                    <div v-if="(details.sub_accounts.length === 0)">
                        <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.subAccountInfo') }}</p>
                        <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" value="サブアカウントは作成されていません" disabled>
                        </div>
                    </div>
                    <!-- サブアカウント情報,Sub Account Info -->

                    <!-- フォルスクラブID情報,Force Club ID Info -->
                    <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.forceClubIdInfo') }}</p>
                    <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.forceClubId')}: ${details.force_club_id}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.forceStatus')}: ${details.force_club_status}`" disabled>
                    </div>
                    <!--/ フォルスクラブID情報,Force Club ID Info -->

                    <!-- フォルシング設定状況,Forthing Setting Status -->
                    <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.forthingSettingStatus') }}</p>
                    <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" :value="`・${$t('user.forthingStatus')}: ${details.forthing_status}`" disabled>
                    </div>
                    <!-- /フォルシング設定状況,Forthing Setting Status -->

                    <!-- KYC -->
                    <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.kyc') }}</p>
                    <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" :value="`・${$t('status')}: ${details.kyc_status}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('approvedDate')}: ${details.approved_date}`" disabled>
                    </div>
                    <!-- /KYC -->

                    <!-- Force Tab アクティベーション,Force Tab Activation -->
                    <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.forceTabActivation') }}</p>
                    <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" :value="`・${$t('status')}: ${details.force_club_activation}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('completionDate')}: ${details.activated_date}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('serialNumber')}: ${details.serial_number}`" disabled>
                    </div>
                    <!-- /Force Tab アクティベーション,Force Tab Activation -->

                    <!-- フォルシング開始手続き,Forthing Start Procedure -->
                    <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.forthingStartProcedure') }}</p>
                    <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" :value="`・${$t('status')}: ${details.forthing_procedure}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('startingDate')}: ${details.starting_date}`" disabled>
                        <textarea rows="3" class="form-control mb-2" :value="`・${$t('requirementHEC')}: ${details.required_hec}`" disabled></textarea>
                        <input type="text" class="form-control mb-2" :value="`・${$t('hecSavingStatus')}: ${details.is_applied_for_hec_saving}`" disabled>
                        {{ $t('autoHECSaving') }}
                        <input type="text" class="form-control mb-2" :value="`・${$t('autoHECSavingForthing')}: ${details.automatic_hec_saving_setting_forthing_plan}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('autoHECSavingExBonus')}: ${details.automatic_hec_saving_setting_exbonus_plan}`" disabled>
                        {{ $t('walletAddress') }}
                        <textarea rows="3" class="form-control mb-2" :value="`・${$t('walletAddress1year')}: ${details.one_year_plan_address}`" disabled></textarea>
                        <textarea rows="3" class="form-control mb-2" :value="`・${$t('walletAddress2year')}: ${details.two_years_plan_address}`" disabled></textarea>
                    </div>
                    <!-- /フォルシング開始手続き,Forthing Start Procedure -->

                    <!-- EXボーナスタイトル,EX Bonus Title -->
                    <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.exBonusTitle') }}</p>
                    <div class= "mx-auto user-details-text">
                        <input type="text" class="form-control mb-2" :value="`・${$t('exBonusMonthTitle')}: ${details.ex_bonus_month_title}`" disabled>
                        <input type="text" class="form-control mb-2" :value="`・${$t('exBonusMaxTitle')}: ${details.ex_bonus_max_title}`" disabled>
                    </div>
                    <!-- /EXボーナスタイトル,EX Bonus Title -->

                    <!-- 2段階認証 -->
                    <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.google2saActive') }}</p>
                    <div class= "mx-auto user-details-text input-group">
                        <input type="text" class="form-control mb-2" :value="`${details.google2sa_active}`" disabled>
                        <div class="input-group-append">
                            <button class="btn btn-danger mb-2 google2sa-disable-button" type="button" :disabled="! details.google2sa_is_active" @click="handleGoogle2saDeactivate()">{{ $t('user.deactivate') }}</button>
                        </div>
                    </div>
                    <!-- /2段階認証 -->

                    <!-- ウォレット取引履歴 CSV出力 -->
                    <p class="mb-0 font-weight-bold text-left">{{ $t('user.details.hec_data_history_csv') }}</p>
                    <div class= "mx-auto user-details-text input-group">
                        <select class="form-control" id="hec-data-history-csv-select">
                            <option selected hidden>{{ $t('user.target_year') }}</option>
                            <option v-for="targetYear of targetPeriod" :value="targetYear">{{ targetYear }}</option>
                        </select>
                        <div class="input-group-append">
                            <button class="btn mb-2 hec-data-history-csv-button" type="button" @click="handleOnClickDownloadHecDataHistoryCsv()">{{ $t('user.hec_data_history_csv_button') }}</button>
                        </div>
                        <div id="target_id_err_message_csv"class="err-message-hidden-csv">{{ $t('user.err_message_csv') }}</div>
                    </div>
                    <!-- /ウォレット取引履歴 CSV出力 -->

                    <!-- HECカードステイタス上限設定 -->
                    <div v-if="isSelectedUserDetails && !details.is_enabled && details.force_club_id !== '-'">
                        <button type="button" class="heccard-limit-setting-button mx-auto my-4" @click="handleOnHecCardStatusLimitSettingButton()">HECカードステイタス上限設定</button>
                    </div>
                    <!-- /HECカードステイタス上限設定 -->

                    <!-- 会員資格停止ボタン -->
                    <div v-if="isSelectedUserDetails && !details.is_enabled">
                        <button type="button" class="membership-suspension-button mx-auto my-4" @click="handleOnClickMembershipSuspensionButton()">会員資格を停止する</button>
                    </div>
                    <!-- /会員資格停止ボタン -->

                    <!-- 会員資格停止モーダル -->
                    <div class="overlay" v-show="showMembershipSuspensionModal">
                        <div class="modal-content">
                            <div class="row">
                                <div class=col-1></div>
                                <p class="col-10 text-center h4 font-weight-bold">会員資格の停止</p>
                                <!-- Closeボタン -->
                                <button type="button" class="col-1 close" @click="closeMembershipSuspensionModal()">&times;</button>
                                <!-- /Closeボタン -->
                            </div>
                            <p class="text-center">下記ユーザーの会員資格を停止します。</p>

                            <!-- 会員詳細 -->
                            <div class= "mx-2 p-2 membership-suspension-modal-user-details">
                                <p class="m-0 overflow-auto">{{$t('user.email')}} : {{details.email}}</p>
                                <p class="m-0 overflow-auto">{{$t('user.aubeId')}} : {{details.external_user_id}}</p>
                                <p class="m-0 overflow-auto">会員種別 : {{details.member_type}}</p>
                            </div>
                            <!-- /会員詳細  -->
                            <!-- 会員資格停止理由 -->
                            <div class="m-2">
                                <span class="m-0 font-weight-bold">会員資格停止理由</span><span class="required">【必須】</span>
                                <div>
                                <textarea id="membership-suspension-modal-textarea" class="d-block w-100" rows="3" v-model="suspendReason"></textarea>
                            </div>
                            </div>
                            <!-- /会員資格停止理由 -->
                            <!-- 会員資格停止モーダルボタン -->
                            <button type="button" class="membership-suspension-modal-button mx-auto mt-3" @click="handleOnClickMembershipSuspensionModalButton('membership-suspension-modal-textarea',255)">会員資格を停止する</button>
                            <!-- /会員資格停止モーダルボタン -->
                        </div>
                    </div>
                    <!-- /会員資格停止モーダル -->

                    <!-- 会員資格停止完了モーダル -->
                    <div class="overlay" v-show="showMembershipSuspensionCompletionModal">
                        <div class="modal-content">
                            <div class="row">
                                <div class=col-1></div>
                                <div class="col-10"></div>
                                <!-- Closeボタン -->
                                <button type="button" class="col-1 close" @click="handleOnClickMembershipSuspensionCompletionModalCloseButton()">&times;</button>
                                <!-- /Closeボタン -->
                            </div>
                            <p class="text-center h4 font-weight-bold">下記ユーザーの会員資格を</p>
                            <p class="text-center h4 font-weight-bold">停止しました。</p>
                            <!-- 会員詳細  -->
                            <div class= "mx-2 p-2">
                                <p class="m-0 overflow-auto">{{$t('user.email')}} : {{details.email}}</p>
                                <p class="m-0 overflow-auto">{{$t('user.aubeId')}} : {{details.external_user_id}}</p>
                                <p class="m-0 overflow-auto">会員種別 : {{details.member_type}}</p>
                            </div>
                            <!-- /会員詳細  -->
                        </div>
                    </div>
                    <!-- /会員資格停止完了モーダル -->

                    <!-- 会員資格復活ボタン -->
                    <div v-if="isSelectedUserDetails && details.is_enabled">
                        <button type="button" class="membership-resume-button mx-auto my-4" @click="handleOnClickMembershipResumeButton()">会員資格を復活する</button>
                    </div>
                    <!-- /会員資格復活ボタン -->

                    <!-- 会員資格復活モーダル -->
                    <div class="overlay" v-show="showMembershipResumeModal">
                        <div class="modal-content">
                            <div class="row">
                                <div class=col-1></div>
                                <p class="col-10 text-center h4 font-weight-bold">会員資格の復活</p>
                            <!-- Closeボタン -->
                                <button type="button" class="col-1 close" @click="closeMembershipResumeModal()">&times;</button>
                            <!-- /Closeボタン -->
                            </div>
                            <p class="text-center">下記ユーザーの会員資格を復活します。</p>
                            <!-- 会員詳細  -->
                            <div class= "mx-2 p-2 membership-resume-modal-user-details">
                                <p class="m-0 overflow-auto">{{$t('user.email')}} : {{details.email}}</p>
                                <p class="m-0 overflow-auto">{{$t('user.aubeId')}} : {{details.external_user_id}}</p>
                                <p class="m-0 overflow-auto">会員種別 : {{details.member_type}}</p>
                            </div>
                            <!-- /会員詳細  -->
                            <!-- 会員資格復活モーダルボタン -->
                            <button type="button" class="membership-resume-modal-button mx-auto mt-3" @click="handleOnClickMembershipResumeModalButton()">会員資格を復活する</button>
                            <!-- /会員資格復活モーダルボタン -->
                        </div>
                    </div>
                    <!-- /会員資格復活モーダル -->

                    <!-- 会員資格復活完了モーダル -->
                    <div class="overlay" v-show="showMembershipResumeCompletionModal">
                        <div class="modal-content">
                            <div class="row">
                                <div class=col-1></div>
                                <div class="col-10"></div>
                                <!-- Closeボタン -->
                                <button type="button" class="col-1 close" @click="handleOnClickMembershipResumeCompletionModalCloseButton()">&times;</button>
                                <!-- /Closeボタン -->
                            </div>
                            <p class="text-center h4 font-weight-bold">下記ユーザーの会員資格を</p>
                            <p class="text-center h4 font-weight-bold">復活しました。</p>
                            <!-- 会員詳細  -->
                            <div class= "mx-2 p-2">
                                <p class="m-0 overflow-auto">{{$t('user.email')}} : {{details.email}}</p>
                                <p class="m-0 overflow-auto">{{$t('user.aubeId')}} : {{details.external_user_id}}</p>
                                <p class="m-0 overflow-auto">会員種別 : {{details.member_type}}</p>
                            </div>
                            <!-- /会員詳細 -->
                        </div>
                    </div>
                    <!-- /会員資格復活完了モーダル -->

                    <!-- HECカードステイタス上限設定モーダル -->
                    <div class="overlay" v-show="showHecCardStatusLimitSettingModal">
                        <div class="modal-content">
                            <div class="row">
                                <div class=col-1></div>
                                <p class="col-10 text-center h4 font-weight-bold">HECカードステイタス上限設定</p>
                                <!-- Closeボタン -->
                                <button type="button" class="col-1 close" @click="closeHecCardStatusLimitSettingModal()">&times;</button>
                                <!-- /Closeボタン -->
                            </div>
                            <p class="text-center">HECカードステイタスの上限を設定します。</p>
                            <!-- 会員詳細 -->
                            <div class= "mx-2 p-2 membership-suspension-modal-user-details">
                                <p class="m-0 overflow-auto">{{$t('user.email')}} : {{details.email}}</p>
                                <p class="m-0 overflow-auto">{{$t('user.aubeId')}} : {{details.external_user_id}}</p>
                                <p class="m-0 overflow-auto">会員種別 : {{details.member_type}}</p>
                            </div>
                            <!-- /会員詳細  -->

                            <!-- HECカードステイタス -->
                            <div class="m-2">
                                <span class="m-0 font-weight-bold">HECカードステイタス</span><span class="required">【必須】</span>
                                <select class="form-control" id="heccard-status-limit-setting-modal-select" v-model="hec_card_status">
                                    <option v-for="option in heccardStatusItems" :value="option.value">{{ option.item }}</option>
                                </select>
                            </div>
                            <!-- /HECカードステイタス -->
                            <!-- HECカードステイタス上限設定更新ボタン -->
                            <button type="button" class="heccard-status-limit-setting-modal-button mx-auto mt-3" @click="handleOnClickHecCardStatusLimitSettingConfirmButton(hec_card_status)">更新</button>
                            <!-- /HECカードステイタス上限設定更新ボタン -->
                        </div>
                    </div>
                    <!-- /HECカードステイタス上限設定モーダル -->

                    <!-- HECカードステイタス上限設定確認モーダル -->
                    <div class="overlay" v-show="showHecCardStatusLimitSettingConfirmModal">
                        <div class="modal-content">
                            <div class="row">
                                <div class=col-1></div>
                                <p class="col-10 text-center h4 font-weight-bold">【確認】HECカードステイタス上限設定</p>
                                <!-- Closeボタン -->
                                <button type="button" class="col-1 close" @click="closeHecCardStatusLimitSettingConfirmModal()">&times;</button>
                                <!-- /Closeボタン -->
                            </div>
                            
                            <!-- 会員詳細 -->
                            <div class= "mx-2 p-2 membership-suspension-modal-user-details">
                                <p class="m-0 overflow-auto">{{$t('user.email')}} : {{details.email}}</p>
                                <p class="m-0 overflow-auto">{{$t('user.aubeId')}} : {{details.external_user_id}}</p>
                                <p class="m-0 overflow-auto">会員種別 : {{details.member_type}}</p>
                                <p class="m-0 overflow-auto">HECカードステイタス上限設定 : {{this.confirm_hec_card_status}}</p>
                            </div>
                            <!-- /会員詳細  -->
                            
                            <!-- HECカードステイタス上限設定確定ボタン -->
                            <button type="button" class="heccard-status-limit-setting-modal-button mx-auto mt-3" @click="handleOnClickHecCardStatusLimitSettingButton(hec_card_status)">確定</button>
                            <!-- /HECカードステイタス上限設定確定ボタン -->
                        </div>
                    </div>
                    <!-- /HECカードステイタス上限設定確認モーダル -->

                    <!-- HECカードステイタス上限設定完了モーダル -->
                    <div class="overlay" v-show="showHecCardStatusLimitSettingCompletionModal">
                        <div class="modal-content">
                            <div class="row">
                                <div class=col-1></div>
                                <p class="col-10 text-center h4 font-weight-bold">【完了】HECカードステイタス上限設定</p>
                                <!-- Closeボタン -->
                                <button type="button" class="col-1 close" @click="handleOnClickHecCardStatusLimitSettingCompletionModalCloseButton()">&times;</button>
                                <!-- /Closeボタン -->
                            </div>
                            <!-- 会員詳細  -->
                            <div class= "mx-2 p-2 membership-suspension-modal-user-details">
                                <p class="m-0 overflow-auto">{{$t('user.email')}} : {{details.email}}</p>
                                <p class="m-0 overflow-auto">{{$t('user.aubeId')}} : {{details.external_user_id}}</p>
                                <p class="m-0 overflow-auto">会員種別 : {{details.member_type}}</p>
                                <p class="m-0 overflow-auto">HECカードステイタス上限設定 : {{this.hec_card_status_message}}</p>
                            </div>
                            <!-- /会員詳細 -->
                        </div>
                    </div>
                    <!-- /HECカードステイタス上限設定完了モーダル -->

                    <!-- Google2段階認証無効化モーダル -->
                    <div class="overlay" v-show="showGoogle2saDeactivateModal">
                        <div class="modal-content">
                            <div class="row">
                                <div class=col-1></div>
                                <p class="col-10 text-center h4 font-weight-bold">Google2段階認証解除</p>
                                <!-- Closeボタン -->
                                <button type="button" class="col-1 close" @click="closeGoogle2saDeactivateModel()">&times;</button>
                                <!-- /Closeボタン -->
                            </div>
                            <p class="text-center">下記ユーザーの2段階認証を解除します。</p>

                            <!-- 会員詳細 -->
                            <div class= "mx-2 p-2 membership-suspension-modal-user-details">
                                <p class="m-0 overflow-auto">{{$t('user.email')}} : {{details.email}}</p>
                                <p class="m-0 overflow-auto">{{$t('user.aubeId')}} : {{details.external_user_id}}</p>
                            </div>
                            <!-- /会員詳細  -->
                            <!-- Google2段階認証無効化モーダルボタン -->
                            <button type="button" class="google2sa-deactivate-modal-button mx-auto mt-3" @click="handleOnClickGoogle2saDeactivateModalButton()">Google2段階認証を解除する</button>
                            <!-- /Google2段階認証無効化モーダルボタン -->
                        </div>
                    </div>
                    <!-- /Google2段階認証無効化モーダル -->

                    <!-- Google2段階認証無効化完了モーダル -->
                    <div class="overlay" v-show="showGoogle2saDeactivateCompletionModal">
                        <div class="modal-content">
                            <div class="row">
                                <div class=col-1></div>
                                <div class="col-10"></div>
                                <!-- Closeボタン -->
                                <button type="button" class="col-1 close" @click="closeGoogle2saDeactivateCompletionModel()">&times;</button>
                                <!-- /Closeボタン -->
                            </div>
                            <p class="text-center h4 font-weight-bold">下記ユーザーのGoogle2段階認証を</p>
                            <p class="text-center h4 font-weight-bold">解除しました。</p>
                            <!-- 会員詳細  -->
                            <div class= "mx-2 p-2">
                                <p class="m-0 overflow-auto">{{$t('user.email')}} : {{details.email}}</p>
                                <p class="m-0 overflow-auto">{{$t('user.aubeId')}} : {{details.external_user_id}}</p>
                            </div>
                            <!-- /会員詳細  -->
                        </div>
                    </div>
                    <!-- /Google2段階認証無効化完了モーダル -->

                </div>
            </div>
            <!-- /User Details -->
        </div>
    </div>

</template>

<script>
import usersService     from "./../services/UsersService.js";
import FilterBar        from "../components/FilterBar";
import downloadFileUtil from "./../utils/DownloadFileUtil";
import Paginate         from "../components/Paginate";
import moment           from "moment";
import { FORTHING_STATUS_FIRST_COMPLETE, FORTHING_STATUS_FIRST_INCOMPLETE, FORTHING_STATUS_SECOND_COMPLETE, FORTHING_STATUS_SECOND_INCOPLETE } from '../utils/Const';
import BN               from 'bignumber.js'
import tokenService from './../services/TokenService';
//import AuthUtil         from '../utils/AuthUtil';
//import { AUTHORIZER, APPROVER } from '../utils/AuthUtil';

export default {
    name: "users-list",

    components: {
        FilterBar,
        Paginate,
    },

    data() {
        return {
            list: [],

            pagination: {
                total_pages:  0,
                current_page: 1,
                per_page:     5,
                total:        0,
            },

            filter: {
                email_or_force_club_id: "",
                last_name:              "",
                first_name:             "",
                and_search:              1,
            },

                filterOrder: {
                order:     "external_user_id",
                direction: "asc",
            },

            displayItems: [
                {
                    name: "5",
                    value: 5,
                },
                {
                    name: "10",
                    value: 10,
                },
                {
                    name: "50",
                    value: 50,
                },
                {
                    name: "100",
                    value: 100,
                },
                {
                    name: "300",
                    value: 300,
                },
                {
                    name: "All",
                    value: 1000000,
                },
            ],

            heccardStatusItems: [],
            
            details: {
                email:                 '',
                external_user_id:      '',
                id:                    '',
                first_name:            '',
                last_name:             '',
                birth_date:            '',
                member_status:         '',
                member_type:           '',
                sub_accounts:          [],
                force_club_id:         '',
                force_club_status:     '',
                forthing_status:       '',
                kyc_status:            '',
                approved_date:         '',
                force_club_activation: '',
                activated_date:        '',
                serial_number:         '',
                forthing_procedure:    '',
                starting_date:         '',
                ex_bonus_month_title:  '',
                ex_bonus_max_title:    '',
                suspend_setting:       {},
                //停止理由コメント
                comment:               '',
                //会員資格停止日時
                suspended_at:          '',
                //会員資格の停止の状態 true:停止中 false:稼働中
                is_enabled:            '',
                google2sa_active:      '-',
                google2sa_is_active:   false,
                required_hec:          '',
                is_applied_for_hec_saving:   '',
                automatic_hec_saving_setting_forthing_plan:  '',
                automatic_hec_saving_setting_exbonus_plan:   '',
                one_year_plan_address:  '',
                two_years_plan_address: '',
                forthingStatusFirstIncomplete:  0,
                forthingStatusFirstComplete:    0,
                forthingStatusSecondIncomplete: 0,
                forthingStatusSecondComplete:   0,
            },

            activeItem: null,

            selectedItem: null,

            //会員資格停止モーダルの表示状態
            showMembershipSuspensionModal: false,

            //会員資格停止完了モーダルの表示状態
            showMembershipSuspensionCompletionModal: false,

            //会員資格復活モーダルの表示状態
            showMembershipResumeModal: false,

            //会員資格復活完了モーダルの表示状態
            showMembershipResumeCompletionModal: false,

            //HECカードステイタス上限設定モーダルの表示状態
            showHecCardStatusLimitSettingModal: false,

            //HECカードステイタス上限設定確認モーダルの表示状態
            showHecCardStatusLimitSettingConfirmModal: false,

            //HECカードステイタス上限設定完了モーダルの表示状態
            showHecCardStatusLimitSettingCompletionModal: false,

            // Google2段階認証無効化モーダルの表示状態
            showGoogle2saDeactivateModal: false,

            // Google2段階認証無効化完了モーダルの表示状態
            showGoogle2saDeactivateCompletionModal: false,

            //資格停止者で絞り込むボタンの表示状態
            showMembershipSuspensionNarrowdownButton: true,

            //すべてを表示するボタンの表示状態
            showFullDisplayButton: false,

            //DisplayItemsとPaginationの表示状態
            showDisplayItemsPagination: true,

            //会員資格停止理由コメント
            suspendReason: "",

            //会員資格を停止したユーザー
            suspendedUser: {},

            //会員資格を復活したユーザー
            resumedUser: {},

            //UserDetailsの選択状態
            isSelectedUserDetails: false,

            suspendedOnly: false,

            // 会員の停止・復活ボタンの表示フラグ
            // auth_flg: true,

            hec_card_status: 0,

            confirm_hec_card_status: '',

            hec_card_status_message: '',

            hecCardStatusLimitUser: {},

            item: '',

            // ウォレット取引履歴 CSV出力
            targetPeriod: [],
            startYear: '',
            date: '',
            period: '',
            targetYear: '',
        };
    },

    async created() {
        // 今後、権限による会員資格停止・復活ボタンの表示・非表示制御を追加する可能性があるため一時コメントアウト
        // this.isAuth = AuthUtil.getAuth('role');
        // const admin_user_info = JSON.parse(this.isAuth);
        // this.role = Number(admin_user_info.role);
        // if (this.role === AUTHORIZER || this.role === APPROVER) {
        //     this.auth_flg = true;
        // } else {
        //     this.auth_flg = false;
        // }
        
        // ウォレット取引履歴 CSV出力 対象期間
        this.startYear = 2019
        this.date      = new Date()
        this.year      = this.date.getFullYear()
        this.period    = Number(this.year) - this.startYear

        for(let i = 0; i < this.period + 1; i++){
            this.targetPeriod.push(this.year - i)
        }

    },

    mounted() {
        this.forthingStatusFirstIncomplete    = FORTHING_STATUS_FIRST_INCOMPLETE;
        this.forthingStatusFirstComplete      = FORTHING_STATUS_FIRST_COMPLETE;
        this.forthingStatusSecondIncomplete   = FORTHING_STATUS_SECOND_INCOPLETE;
        this.forthingStatusSecondComplete     = FORTHING_STATUS_SECOND_COMPLETE;
    },

    methods: {
        getDate(value) {
            if (!value) {
                return '';
            }

            if (value === '-') {
                return value;
            }

            return moment(String(value)).format('YYYY-MM-DD');
        },

　　　　　//Searchボタンをクリックした時に処理する
        handleOnClickSearchButton() {
            this.search();
        },

        // 検索する時に処理する
        search(){
            this.fetchUserList();
            this.refreshUserDetails();
            this.setSelectedUserDetailsState(false);
        },

        //ページネーションのカレントページを設定する
        setPaginationCurrentPage(page)
        {
            this.pagination.current_page = page;
        },

        //ユーザー情報を取得しリストに設定する
        fetchUserList(){
            if (this.suspendedOnly) {
                this.fetchSuspendedUserList();
            } else {
                this.fetchAllUserList();
            }
        },

        //ディスプレイアイテムを選択した時に処理する
        handleOnClickDisplayItemsSelect() {
            this.search();
        },

        setListArray(data = []) {
            this.list = [];
            for (let i in data) {
                if (data.hasOwnProperty(i) && i !== "meta") {
                    this.list.push(data[i]);
                }
            }
        },

        makeParams() {
            let params = { ...this.filter };
            params.email_or_force_club_id = encodeURIComponent(
                params.email_or_force_club_id
            );
            params.page      = this.pagination.current_page;
            params.size      = this.pagination.per_page;
            params.order     = this.filterOrder.order;
            params.direction = this.filterOrder.direction;
            return params;
        },

        async fetchAllUserList() {
            this.list    = [];
            const params = this.makeParams();
            const list   = await usersService.getList(params);
            this.setListArray(list.data);
            this.pagination = list.data.meta.pagination;
        },

        async fetchSuspendedUserList() {
            this.list    = [];
            const params = this.makeParams();
            const list   = await usersService.getSuspendedList(params);
            this.setListArray(list.data);

            this.pagination = list.data.meta.pagination;
        },

        handleOnClickOrder(columnName) {
            if (this.filterOrder.order === columnName) {
                this.filterOrder.direction =
                    this.filterOrder.direction === "asc"
                        ? "desc"
                        : "asc";
            } else {
                this.filterOrder.order = columnName;
                this.filterOrder.direction = "asc";
            }

            fetchUserList();
        },

        checkOrder(columnName, direction) {
            if (columnName !== this.filterOrder.order) {
                return true;
            } else {
                return direction === this.filterOrder.direction;
            }
        },

        handleOnclickJoin(value) {
            this.$set(this.filter, "and_search", value);
        },

        parseMemberType(item) {
            return item.member_type_id
                        ? item.member_type_id
                        : "0";
        },

        parseKycStatus(item) {
            return item.kyc_settings
                        ? item.kyc_settings.status
                        : "0";
        },

        isKycStatusApproved(item) {
            return this.parseKycStatus(item) === "2";
        },

        parseForceStatus(item) {
            return item.force_club
                    ? item.force_club.status
                    : "0";
        },

        isForceTabActivated(item) {
            return this.parseForceStatus(item) === "1";
        },
        
        parseForthingStatus(item) {
            return item.forthing_procedure_status
                        ? item.forthing_procedure_status
                        : this.forthingStatusFirstIncomplete;
        },
        
        isForthingStarted(item) {
            if (Number(this.parseForthingStatus(item)) === this.forthingStatusFirstComplete || Number(this.parseForthingStatus(item)) === this.forthingStatusSecondComplete){
                return true;
            }else{
                return false;
            }
        },

        parseHECSaving(is_applied_for_hec_saving) {
            if(is_applied_for_hec_saving) {
                return "1";
            }else{
                return "0";
            }
        },

        parseAutoForthingPlan(automatic_hec_saving_setting_forthing_plan) {
            return automatic_hec_saving_setting_forthing_plan
                        ? automatic_hec_saving_setting_forthing_plan
                        : "0";
        },

        parseAutoExbonusPlan(automatic_hec_saving_setting_exbonus_plan) {
            return automatic_hec_saving_setting_exbonus_plan
                        ? automatic_hec_saving_setting_exbonus_plan
                        : "0";
        },

        showDetails(item) {
            this.setUserInfo(item);
            this.setSubAccountInfo(item);
            this.setForceClubIdInfo(item);
            this.setForthingSettingsInfo(item);
            this.setExBonusTitleInfo(item);
            this.setGoogle2saActiveInfo(item);
            this.setForthingProcedure();

            // 一般無料会員の場合、force_club_id以下にハイフンをセットする
            if (this.isGeneralFreeMember(item)) {
                this.setHyphenfollowingForceClubId();
            }
        },

        setUserInfo(item) {
            this.details.email            = item.email                || '-';
            this.details.external_user_id = item.external_user_id     || '-';
            this.details.id               = item.id                   || '-';
            this.details.first_name       = item.user_data.first_name || '-';
            this.details.last_name        = item.user_data.last_name  || '-';
            this.details.birth_date       = item.user_data.birth_date || '-';
            this.details.member_status    = item.member_status
                                                ? `${this.$t(`user.memberStatuses.${ item.member_status }`)}`
                                                : '-';
            this.details.member_type      = item.member_type_id
                                                ? `${this.$t(`user.memberTypeId[${this.parseMemberType(item)}]`)}`
                                                : '-';
            this.details.suspend_setting  = item.suspend_setting
                                                ? item.suspend_setting
                                                : null
            this.details.comment          = item.suspend_setting
                                                ? item.suspend_setting.comment
                                                : '';
            this.details.suspended_at          = item.suspend_setting
                                                ? item.suspend_setting.suspended_at
                                                : '';
            this.details.is_enabled           = item.suspend_setting
                                                ? item.suspend_setting.is_enabled
                                                : '';
        },

        setSubAccountInfo(item) {
            this.details.sub_accounts = item.sub_accounts;
        },

        setForceClubIdInfo(item) {
            this.details.force_club_id     = item.force_club_id || '-';
            this.details.force_club_status = item.force_club_status === '0'
                                                ? this.$t('user.membership.paid')
                                                : this.$t('user.membership.unpaid');
        },

        setForthingSettingsInfo(item) {
            this.details.forthing_status       = item.forthing_status
                                                    ? this.$t('user.forthing.active')
                                                    : this.$t('user.forthing.inactive');

            this.details.kyc_status            = this.$t(`kyc.statuses[${this.parseKycStatus(item)}]`);
            this.details.approved_date         = this.isKycStatusApproved(item)
                                                    ? this.getDate(item.kyc_settings.updated_at)
                                                    : "";

            this.details.force_club_activation = this.$t(`force.statuses[${this.parseForceStatus(item)}]`);
            this.details.activated_date        = this.isForceTabActivated(item)
                                                    ? this.getDate(item.force_club.updated_at)
                                                    : '';
            this.details.serial_number         = this.isForceTabActivated(item)
                                                    ? item.force_club.force_club_serial
                                                    : '';

            this.details.forthing_procedure    = this.$t(`forthing.statuses.${this.parseForthingStatus(item)}`);
            this.details.starting_date         = this.isForthingStarted(item)
                                                    ? this.getDate(item.latest_membership_fee.enables_at)
                                                    : '';
        },

        setExBonusTitleInfo(item) {
            this.details.ex_bonus_month_title = item.ex_bonus_month_title
                                                    ? item.ex_bonus_month_title.short_name
                                                    : '';
            this.details.ex_bonus_max_title   = item.ex_bonus_max_title
                                                    ? item.ex_bonus_max_title.short_name
                                                    : '';
        },

        setGoogle2saActiveInfo(item) {
            this.details.google2sa_active = item.google2sa_active
                                    ? this.$t('google2sa.statuses[1]')
                                    : this.$t('google2sa.statuses[0]');
            this.details.google2sa_is_active = item.google2sa_active;
        },

        async setForthingProcedure() {
            const procedure_data = (await usersService.forthingProcedureInfo(this.details.id)).data;

            BN.set({ROUNDING_MODE:BN.ROUND_UP})
            this.details.required_hec                               = (new BN(procedure_data.required_hec)).toFixed(8) + " HEC";
            this.details.is_applied_for_hec_saving                  = this.$t(`hecSaving.statuses[${this.parseHECSaving(procedure_data.is_applied_for_hec_saving)}]`);
            this.details.automatic_hec_saving_setting_forthing_plan = this.$t(`autoForthingPlan.statuses.${this.parseAutoForthingPlan(procedure_data.automatic_hec_saving_setting_forthing_plan)}`);
            this.details.automatic_hec_saving_setting_exbonus_plan  = this.$t(`autoExbonusPlan.statuses.${this.parseAutoExbonusPlan(procedure_data.automatic_hec_saving_setting_exbonus_plan)}`);
            this.details.one_year_plan_address                      = procedure_data.one_year_plan_address ? procedure_data.one_year_plan_address : '-';
            this.details.two_years_plan_address                     = procedure_data.two_years_plan_address ? procedure_data.two_years_plan_address : '-';
        },

        isGeneralFreeMember(item) {
            return this.parseMemberType(item) === 2100 ||
                   this.parseMemberType(item) === 2101 ||
                   this.parseMemberType(item) === 2200 ||
                   this.parseMemberType(item) === 2201;
        },

        setHyphenfollowingForceClubId() {
                this.details.force_club_id         = '-';
                this.details.force_club_status     = '-';
                this.details.forthing_status       = '-';
                this.details.kyc_status            = '-';
                this.details.approved_date         = '-';
                this.details.force_club_activation = '-';
                this.details.activated_date        = '-';
                this.details.serial_number         = '-';
                this.details.forthing_procedure    = '-';
                this.details.starting_date         = '-';
                this.details.ex_bonus_month_title  = '-';
                this.details.ex_bonus_max_title    = '-';
        },

        async handleOnClickDownloadCsv() {
            const result = await usersService.getListExport();
            downloadFileUtil.download(result.data, "users.csv");
        },

        //会員資格停止モーダルを開く。
        openMembershipSuspensionModal() {
            this.showMembershipSuspensionModal = true;
        },

        //会員資格停止モーダルを閉じる。
        closeMembershipSuspensionModal() {
            this.suspendReason = '';
            this.showMembershipSuspensionModal = false;
        },

        //会員資格を停止する。
        async suspendUser() {
            const suspendedReason = this.suspendReason;
            this.suspendedUser = await usersService.suspendUser(this.details.id, suspendedReason);
        },

        //会員資格を復活する。
        async resumeUser() {
            this.resumedUser = await usersService.resumeUser(this.details.id);
        },

        //会員資格停止完了モーダルを開く。
        openMembershipSuspensionCompletionModal() {
            this.showMembershipSuspensionCompletionModal = true;
        },

        //会員資格停止完了モーダルを閉じる。
        closeMembershipSuspensionCompletionModal() {
            this.showMembershipSuspensionCompletionModal = false;
        },

        //会員資格復活モーダルを開く。
        openMembershipResumeModal() {
            this.showMembershipResumeModal = true;
        },

        //会員資格復活モーダルを閉じる。
        closeMembershipResumeModal() {
            this.showMembershipResumeModal = false;
        },

        //会員資格復活完了モーダルを開く。
        openMembershipResumeCompletionModal() {
            this.showMembershipResumeCompletionModal = true;
        },

        //会員資格復活完了モーダルを閉じる。
        closeMembershipResumeCompletionModal() {
            this.showMembershipResumeCompletionModal = false;
        },

        //HECカードステイタス上限設定を更新する。
        async updateHecCardStatusLimitSetting() {
            const hec_card_status = this.hec_card_status;
            this.hecCardStatusLimitUser = await usersService.addHecCardStatusLimit(this.details.id, hec_card_status);
        },

        //HECカードステイタス上限設定モーダルを開く。
        async openHecCardStatusLimitSettingModal() {
            const status_list = await usersService.getHecCardStatusList();
            this.heccardStatusItems = status_list.data;
            this.showHecCardStatusLimitSettingModal = true;
        },

        //HECカードステイタス上限設定モーダルを閉じる。
        closeHecCardStatusLimitSettingModal() {
            this.showHecCardStatusLimitSettingModal = false;
        },

        //HECカードステイタス上限設定確認モーダルを開く。
        openHecCardStatusLimitSettingConfirmModal() {
            this.confirm_hec_card_status = this.heccardStatusItems[this.hec_card_status]['item']
            this.showHecCardStatusLimitSettingConfirmModal = true;
        },

        //HECカードステイタス上限設定確認モーダルを閉じる。
        closeHecCardStatusLimitSettingConfirmModal() {
            this.hec_card_status_message = this.heccardStatusItems[this.hec_card_status]['item']
            this.confirm_hec_card_status = '';
            this.hec_card_status = 0;
            this.showHecCardStatusLimitSettingConfirmModal = false;
        },

        //HECカードステイタス上限設定完了モーダルを開く。
        openHecCardStatusLimitSettingCompletionModal() {
            this.showHecCardStatusLimitSettingCompletionModal = true;
        },

        //HECカードステイタス上限設定完了モーダルを閉じる。
        closeHecCardStatusLimitSettingCompletionModal() {
            this.hec_card_status_message = '';
            this.showHecCardStatusLimitSettingCompletionModal = false;
        },

        //UserDetailsの選択状態を設定する。
        setSelectedUserDetailsState(state){
            this.isSelectedUserDetails = state;
        },

        //会員資格停止ボタンをクリックした時に処理する。
        handleOnClickMembershipSuspensionButton(){
            this.openMembershipSuspensionModal();
        },

        //HECカードステイタス上限設定ボタンをクリックした時に処理する。
        handleOnHecCardStatusLimitSettingButton(){
            this.openHecCardStatusLimitSettingModal();
        },

        //HECカードステイタス上限設定モーダル更新ボタンをクリックした時に処理する。
        async handleOnClickHecCardStatusLimitSettingConfirmButton(hec_card_status){
            this.closeHecCardStatusLimitSettingModal();
            this.openHecCardStatusLimitSettingConfirmModal();
        },
        
        //会員資格復活完了モーダルクローズボタンをクリックした時に処理する。
        handleOnClickHecCardStatusLimitSettingCompletionModalCloseButton(){
            this.closeHecCardStatusLimitSettingCompletionModal();
        },
        //HECカードステイタス上限設定確認モーダル確定ボタンをクリックした時に処理する。
        async handleOnClickHecCardStatusLimitSettingButton(hec_card_status){
            const p = await this.updateHecCardStatusLimitSetting(hec_card_status);
            this.closeHecCardStatusLimitSettingConfirmModal();
            this.openHecCardStatusLimitSettingCompletionModal();
        },

        //会員資格停止モーダルボタンをクリックした時に処理する。
        async handleOnClickMembershipSuspensionModalButton(name,maxValue){
            if(this.validateBlank(name) || this.validateMoreThanMaxValue(name,maxValue)){
                return
            }

            const p = await this.suspendUser();
            this.closeMembershipSuspensionModal();
            this.openMembershipSuspensionCompletionModal();
        },

        // Google2段階認証無効化処理
        handleGoogle2saDeactivate() {
            this.showGoogle2saDeactivateModal = true;
            document.activeElement.blur();
        },

        // Google2段階認証無効化モーダル閉じる処理
        closeGoogle2saDeactivateModel() {
            this.showGoogle2saDeactivateModal = false;
        },

        // Google2段階認証無効化モーダルボタンクリック処理
        async handleOnClickGoogle2saDeactivateModalButton() {
            this.showGoogle2saDeactivateModal = false;
            await usersService.deactivate2sa(this.details.id)
            this.showGoogle2saDeactivateCompletionModal = true;
        },

        // Google2段階認証無効化完了モーダルを閉じる
        closeGoogle2saDeactivateCompletionModel() {
            this.showGoogle2saDeactivateCompletionModal = false;
            this.search();
        },

        //空白かのバリデーションをする。
        validateBlank(name){
            return document.getElementById(name).value=="";
        },

        //最大値より多いかのバリデーションをする。
        validateMoreThanMaxValue(name,maxValue){
            return document.getElementById(name).value.length > maxValue;
        },

        //会員資格停止完了モーダルクローズボタンをクリックした時に処理する。
        handleOnClickMembershipSuspensionCompletionModalCloseButton(){
            this.search();
            this.closeMembershipSuspensionCompletionModal();
        },

        //会員資格復活ボタンをクリックした時に処理する。
        handleOnClickMembershipResumeButton(){
            this.openMembershipResumeModal();
        },

        //会員資格復活モーダルボタンをクリックした時に処理する。
        async handleOnClickMembershipResumeModalButton(){
            const p = await this.resumeUser();
            this.closeMembershipResumeModal();
            this.openMembershipResumeCompletionModal();
        },

        //会員資格復活完了モーダルクローズボタンをクリックした時に処理する。
        handleOnClickMembershipResumeCompletionModalCloseButton(){
            this.search();
            this.closeMembershipResumeCompletionModal();
        },

        // ウォレット取引履歴 CSV出力
        async handleOnClickDownloadHecDataHistoryCsv() {
            //対象期間取得
            this.targetYear = document.getElementById("hec-data-history-csv-select").value

            //CSV出力値取得
            const resultList = await Promise.all([
                tokenService.getHecDataHistoryListExport({
                    email: this.details.email,
                    external_user_id: this.details.external_user_id,
                    id: this.details.id,
                    targetYear: this.targetYear,
                })
            ])

            if(resultList[0].data){
                //該当する実績がある場合 エラー文字非表示 
                document.querySelector('#target_id_err_message_csv').classList.replace('err-message-display-csv', 'err-message-hidden-csv')
                //CSV出力
                downloadFileUtil.download(resultList[0].data, "HecDataHistory_"+ this.targetYear +"_"+ this.details.id +".csv");
            }else{
                //該当する実績がない場合 エラー文字表示
                document.querySelector('#target_id_err_message_csv').classList.replace('err-message-hidden-csv', 'err-message-display-csv')
            } 
        },

        //UserDetailsを初期状態に戻す。
        refreshUserDetails(){
            this.details.email                 = '';
            this.details.external_user_id      = '';
            this.details.id                    = '';
            this.details.first_name            = '';
            this.details.last_name             = '';
            this.details.birth_date            = '';
            this.details.member_status         = '';
            this.details.member_type           = '';
            this.details.sub_accounts          = [];
            this.details.force_club_id         = '';
            this.details.force_club_status     = '';
            this.details.forthing_status       = '';
            this.details.kyc_status            = '';
            this.details.approved_date         = '';
            this.details.force_club_activation = '';
            this.details.activated_date        = '';
            this.details.serial_number         = '';
            this.details.forthing_procedure    = '';
            this.details.starting_date         = '';
            this.details.ex_bonus_month_title  = '';
            this.details.ex_bonus_max_title    = '';
            this.details.google2sa_active      = '-';
            this.details.google2sa_is_active   = false;
            this.details.required_hec          = '';
            this.details.is_applied_for_hec_saving                  = '';
            this.details.automatic_hec_saving_setting_forthing_plan = '';
            this.details.automatic_hec_saving_setting_exbonus_plan  = '';
            this.details.one_year_plan_address                      = '';
            this.details.two_years_plan_address                     = '';
        },

        //資格停止者で絞り込むボタンをクリックした時に処理する。
        handleOnClickMembershipSuspensionNarrowdownButton(){
            this.showMembershipSuspensionNarrowdownButton = false;
            this.showFullDisplayButton = true;
            this.suspendedOnly = true;
            this.setPaginationCurrentPage(1);
            this.search();
        },

        //すべてを表示するボタンをクリックした時に処理する。
        handleOnClickFullDisplayButton(){
            this.suspendedOnly = false;
            this.showFullDisplayButton = false;
            this.showMembershipSuspensionNarrowdownButton = true;
            this.setPaginationCurrentPage(1);
            this.search();
        },

        //リストアイテムのclassを設定する。
        setListItemClass(item){
            //資格停止者のみのリスト表示の場合。
            if(this.suspendedOnly){
                return{
                    'list-item':this.activeItem===item,
                    'selected-item': this.selectedItem===item,
                }
            }
            //全てのリスト表示の場合。
            else if(!this.suspendedOnly){
                if(item.suspend_setting == null ||this.activeItem===item){
                    return{
                        'list-item':this.activeItem===item,
                        'selected-item': this.selectedItem===item,
                    }
                }
                else if(item.suspend_setting != null){
                    return{
                        'list-table-back-ground-color': item.suspend_setting.is_enabled,
                        'list-item':this.activeItem===item,
                        'selected-item': this.selectedItem===item,
                    }
                }
            }
        },
    },
};

</script>

<style scoped>
.filter {
    cursor: pointer;
    white-space: nowrap;
}

.filter .block-with-caret {
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    font-size: 0;
    margin-left: 4px;
    vertical-align: middle;
    white-space: normal;
    width: 10px;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
    margin: 1px 0;
    padding-top: 1px;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    margin: 1px 0;
}

.btn-primary {
    color: #fff !important;
}

.display-item-label {
    margin-top: 10px;
    padding-right: 0;
    text-align: center;
}

.display-item {
    padding-left: 0;
}

.list-table {
    white-space: nowrap;
    max-height: calc(100vh - 320px);
    overflow-x: scroll;
    overflow-y: scroll;
    white-space: nowrap;
}

.detail-block {
    background-color: rgb(215, 229, 242);
    margin: 0 auto 0px;
    max-height: 100vh;
    overflow: auto;
}

/* リストテーブルの背景色 */
.list-table-back-ground-color{
    background-color: #dddddd;
}
/* リストテーブルの背景色 */

.list-item {
    background-color: rgb(191, 191, 191);
}

.selected-item {
    background-color: rgb(215, 229, 242);
}

.sticky-content {
    position: sticky;
    top: 0;
    border-top: none;
    box-shadow: inset 0 2px rgb(223, 226, 230), 0 1px rgb(223, 226, 230);
    background-color: rgb(248, 250, 252);
}

/* 背景色を#C0000にする */
.bg-red {
    background-color: #C00000;
}
/* 背景色を#C0000にする */

/* UserDetailsエリアのテキストエリアの幅を狭くする。*/
.user-details-text {
    width: 14.5rem;
}
/* UserDetailsエリアのテキストエリアの幅を狭くする。*/

/* 会員資格を停止するボタン */
.membership-suspension-button {
    display: block;
    width: 14rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #ffffff;
    background-color: #C00000;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
/* 会員資格を停止するボタン */

/* HECカードステイタス上限設定更新ボタン */
.heccard-limit-setting-button {
    display: block;
    width: 14rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.8;
    color: #ffffff;
    background-color: #C00000;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
/* HECカードステイタス上限設定更新ボタン */

/* 会員資格を復活するボタン */
.membership-resume-button {
    display: block;
    width: 14rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #ffffff;
    background-color: #70AD47;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
/* 会員資格を復活するボタン */

/* 会員資格モーダル */
.overlay {
    /* 要素を重ねた時の順番 */
    z-index:1;

    /* 画面全体を覆う設定 */
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.5);

    /* 画面の中央に要素を表示させる設定 */
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    z-index:2;
    width:26rem;
    padding: 1em;
    background:#fff;
}
/* 会員資格モーダル */

/* 会員資格停止モーダル会員詳細 */
.membership-suspension-modal-user-details {
    border: #D9D9D9;
    border-style: solid;
    border-width: 0.13rem;
    background: #F2DCDB;
}
/* 会員資格停止モーダル会員詳細 */

/* 会員資格復活モーダル会員詳細 */
.membership-resume-modal-user-details {
    border: #D9D9D9;
    border-style: solid;
    border-width: 0.13rem;
    background: #EBF1DE;
}
/* 会員資格復活モーダル会員詳細 */

/* 会員資格停止モーダルテキストエリア */
#membership-suspension-modal-textarea {
    resize: none;
    border: #D9D9D9;
    border-style: solid;
    border-width: 0.13rem;
}
/* 会員資格停止モーダルテキストエリア */

/* 必須テキスト */
.required {
    color: #C00000;
}
/* 必須テキスト */

/* 2段階認証無効化モーダルボタン */
.google2sa-deactivate-modal-button {
    display: block;
    width: 75%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #ffffff;
    background-color: #C00000;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

/* 会員資格停止モーダルボタン */
.membership-suspension-modal-button{
    display: block;
    width: 50%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #ffffff;
    background-color: #C00000;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
/* 会員資格停止モーダルボタン */

/* 会員資格復活モーダルボタン */
.membership-resume-modal-button{
    display: block;
    width: 50%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #ffffff;
    background-color: #70AD47;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
/* 会員資格復活モーダルボタン */

/* HECカードステイタス上限設定更新ボタン */
.heccard-status-limit-setting-modal-button{
    display: block;
    width: 60%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #ffffff;
    background-color: #C00000;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
/* HECカードステイタス上限設定更新ボタン */

/* 2段階認証解除ボタン */
.google2sa-disable-button {
    z-index: 0;
}
/* 2段階認証解除ボタン */

/* ウォレット取引履歴 CSV出力 */
.hec-data-history-csv-button {
    z-index: 0;
    color: #fff;
    background-color: #3490dc;
    border-color: #3490dc;
}
/* ウォレット取引履歴 CSV出力 */
.err-message-hidden-csv {
    visibility:hidden;
    padding-bottom: 10px;
}
.err-message-display-csv {
    color: #e3342f;
    padding-bottom: 10px;
}
</style>
