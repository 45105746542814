<template>
    <div class="pl-3">
        <div class="row">
            <div class="title">{{ $t('forthingContracts.forthingContracts') }}</div>
        </div>
        <div class="row">
            <div class="col-4 mr-3">    
                <div class="form-group">
                    <!-- CSVファイル選択 -->
                    <label for="csvPath">{{ $t('forthingContracts.selectCsv') }}</label>
                    <div class="input-group mb-3" enctype="multipart/form-data">
                        <input type="text" readonly class="form-control" aria-describedby="button-addon2" :value="csvFileName">
                        <div class="input-group-append">
                            <label class="btn btn-secondary" :class="{ disabled: ! enabledSelectCsv }">{{ $t('forthingContracts.reference') }}
                                <input ref="fileComponent" type="file" accept=".csv" class="file-btn" :disabled="! enabledSelectCsv"
                                       @change="handleOnChangeFileInput"/>
                            </label>
                        </div>
                    </div>
                    <!-- フォルシング契約処理 -->
                    <label for="forhingContractsOperation">{{ $t('forthingContracts.forthingContractsOperation') }}</label>
                    <div id="forthingContractsOperation">
                        <button class="btn btn-primary mb-3" :disabled="! enabledForthingContracts" @click="executeForthingContracts">{{ $t('forthingContracts.executeForthingContracts') }}</button>
                    </div>
                    <!-- 取り消し -->
                    <button class="btn btn-secondary mt-5" @click="cancel" :disabled="! enabledCancel">{{ $t('forthingContracts.cancel') }}</button>
                </div>
            </div>
            <div class="col-8 row border-left">
                <!-- アップロード済CSV -->
                <dl class="col-6 float-left">
                    <dt>{{ $t('forthingContracts.uploadedCsv') }}</dt>
                    <dd class="ml-3 file-name">{{ csvFileName }}</dd>
                </dl>
                <!-- CSVエラーチェック結果 -->
                <dl class="col-12">
                    <dt>{{ $t('forthingContracts.checkResult') }}</dt>
                    <dd class="ml-3 result" style="white-space: pre-wrap;">{{ executeResult }}</dd>
                </dl>
           </div>
        </div>
    </div>
</template>
<script>
    import forthingService from './../services/FrothingContractsService';

    export default {

        name: 'forthing-contracts',

        data() {
            return {
                formData: {
                    file: null,
                },
                result: null,
                executeFlg: null,

            }
        },
        
        async mounted() {
            this.fetch();
        },

        methods: {
            async fetch() {
            },
            
            /** 取り消し */
            async cancel() {
                await forthingService.cancel();
                this.result = null;
                this.formData.file = null;
                this.$refs.fileComponent.value = null;
                this.executeFlg = null;
            },

            /** CSVファイルを選択した */
            async handleOnChangeFileInput(e) {
                const files = e.target.files || e.dataTransfer.files;
                const file = files[0];

                // キャンセルボタンが押された時は何もしない
                if (file === undefined) return;

                this.formData.file = file;

                try {
                    // ファイルサイズが10Mを越えるときはアップロードしない
                    if (this.formData.file.size > 1024 * 1024) {
                        this.showErrMessage("FILE_SIZE_EXCEEDED");
                        // fileオブジェクトをクリアする
                        e.target.value = "";
                        this.formData.file = null;
                        return;
                    }

                    const result = await forthingService.postCsv(this.formData);
                    this.executeFlg = null;
                } catch (err) {
                    const errors = err.response.data.errors;
                    if (errors) {
                        if (Array.isArray(errors)) {
                            errors.forEach(errItem => {
                                if (errItem.field) {
                                    this.showErrMessage(errItem.message[0]);
                                }
                            })
                        } else {
                            alert(`CSVのアップロードに失敗しました。\n${errors}`)
                        }
                    } else {
                        alert("CSVのアップロードに失敗しました。");
                    }

                    // fileオブジェクトをクリアする
                    e.target.value = "";
                    this.formData.file = null;
                }
            },

            /** フォルシング契約処理実行 */
            async executeForthingContracts() {
                if(confirm("フォルシング契約処理を行いますがよろしいですか？")){
                    this.executeFlg = true;
                    try {
                        const result = await forthingService.executeForthingContracts();

                        alert("フォルシング契約処理を実行しました。"); 
                        switch (result.data[0]) {
                            case 0:
                                this.result = "フォルシング契約処理が正常終了しました。";
                                break;
                            case 1:
                                this.result = "フォルシング契約処理が異常終了しました。エラーログの確認が必要です。" + "\n参照ログファイル：" + result.data[1];
                                break;
                            case 2:
                                this.result = "エラーレコードが発生しました。エラーログの確認が必要です。" + "\n参照ログファイル：" + result.data[1];
                                break;
                        }
                    } catch (err) {
                        alert(`フォルシング契約処理実行エラー`);
                        this.result = "フォルシング契約処理が実行できませんでした。";
                    }   
                }
            },

            showErrMessage(type) {
                const errMessage = this.$i18n.t('errors.' + type);
                alert(`CSVのアップロードに失敗しました。\n${errMessage}`);
            },
        },

        computed: {
            csvFileName: function() {
                if (this.formData.file == null) {
                    return "";
                } else {
                    return this.formData.file.name;
                }
            },
            executeResult: function() {
                return this.result;
            },           
            enabledSelectCsv: function() {
                return !this.csvFileName;
            },
            enabledForthingContracts: function() {
                return !this.executeFlg && this.csvFileName;

            },
            enabledCancel: function() {
                return !this.executeFlg && this.csvFileName;
            },
        }
    }
</script>

<style>
    label, input, .btn {
        display: block;
    }
    table {
        width: 100%;
        white-space: nowrap;
    }
    .check-result {
        overflow: auto; 
        max-height: 60vh;
    }
    .file-btn {
        display: none;
    }
    .wallet-address, .file-name, .result{
        overflow-wrap: anywhere;
    }
</style>