import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './languages/en.json';
import ja from './languages/ja.json';

Vue.use(VueI18n);


export const i18n = new VueI18n({
    locale: 'ja-JP',
    messages: {
        'en-US': en,
        'ja-JP': ja,
    }
});
