<template>
    <div class="wrapper">
        <sidebar></sidebar>

        <div id="content">
            <router-view></router-view>
            <notifications />
        </div>
    </div>
</template>

<script>
    import Sidebar from './components/Sidebar.vue';

    export default {
        name: 'admin-layout',
        components: {
            Sidebar
        },
        mounted() {

        }
    }
</script>

<style>
    .wrapper {
        display: flex;
        width: 100%;
        align-items: stretch;
    }

    #content {
        width: calc(100% - 250px);
        padding: 20px;
        min-height: 100vh;
    }
</style>
