<template>
    <div class="form-row">
        <slot></slot>
        <div class="form-group col-md-3 search-button">
            <button type="button" class="btn btn-primary search-button-padding text-nowrap" @click="handleOnClickSearch()">{{ $t('search') }}</button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'filter-bar',

        props: {
            search: {
                type: Function
            }
        },

        data(){
            return{

            }
        },

        methods:{
            handleOnClickSearch() {
                if (this.search) {
                    this.search();
                }
            }
        },

        mounted() {

        }
    }
</script>

<style scoped>
    .search-button{
        display: flex;
        align-items: flex-end;
    }
    .search-button-padding {
        padding-left: 3rem;
        padding-right: 3rem;
    }
</style>
