<template>
    <div>
        <div class="title">{{ $t('user.details.title') }}</div>
        <div v-if="user">
            <p><span>{{ $t('user.id') }}:</span> <span class="pl-2">{{ user.id }}</span></p>
            <p><span>{{ $t('user.email') }}:</span> <span class="pl-2">{{ user.email }}</span></p>
            <p><span>{{ $t('user.externalId') }}:</span> <span class="pl-2">{{ user.external_user_id }}</span></p>
            <p><span>{{ $t('user.first') }}:</span> <span class="pl-2">{{ user.userData.first_name }}</span></p>
            <p><span>{{ $t('user.family') }}:</span> <span class="pl-2">{{ user.userData.last_name }}</span></p>
            <p><span>{{ $t('user.birthDate') }}:</span> <span class="pl-2">{{ user.userData.birth_date }}</span></p>
        </div>
    </div>
</template>

<script>
    import usersService from './../services/UsersService.js';

    export default {
        name: 'user-details',
        mounted() {
        },

        data() {
            return{
                user: null,
            }
        },

        async created() {
            await this.getUser();
        },

        methods: {
            async getUser() {

                const result = await usersService.getUser(this.$route.params.id);
                this.user = result.data;
            },
        }
    }
</script>

<style>

</style>
