<template>
    <div>
        <div class="title">{{ $t('bonus.list.head') }}</div>
        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col">{{ $t('bonus.list.levels') }}</th>
                <th scope="col">{{ $t('bonus.list.title') }}</th>
                <th scope="col">{{ $t('bonus.list.conditions') }}</th>
                <th scope="col">{{ $t('bonus.list.remarks') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Lv.1</td>
                <td>Master</td>
                <td>time</td>
                <td>time</td>
            </tr>
            <tr>
                <td>Lv.2</td>
                <td>Master</td>
                <td>time</td>
                <td>time</td>
            </tr>
            <tr>
                <td>Lv.3</td>
                <td>Master</td>
                <td>time</td>
                <td>time</td>
            </tr>
            <tr>
                <td>Lv.4</td>
                <td>Master</td>
                <td>time</td>
                <td>time</td>
            </tr>
            </tbody>
        </table>
        <div class="float-right">
            <button
                type="button"
                class="btn btn-success"
                @click=""
            >
                {{ $t('bonus.button.save') }}
            </button>

            <button
                type="button"
                class="btn btn-outline-secondary"
                @click=""
            >
                {{ $t('bonus.button.cancel') }}
            </button>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'withdrawal-list',
        mounted() {

        }
    }
</script>

<style>

</style>
