<template>
    <div class="modal-payments">
        <div class="modal">
            <div class="modal-header">
                <button
                    type="button"
                    class="btn-close"
                    @click="close">x</button>
            </div>

            <div class="modal-body">
                <div class="col-12">
                    <p>{{ $t('payments.history') }}</p>
                </div>

                <div class="col-12">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">{{ $t('payments.date') }}</th>
                            <th scope="col">{{ $t('payments.amount') }}</th>
                            <th scope="col">{{ $t('payments.status') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in transactions">
                            <td>{{ getDate(item.date) }}</td>
                            <td>{{ item.amount }} {{ item.currency }}</td>
                            <td>{{ $t(`payPal.transactions.statuses[${item.status}]`) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer">
                <button
                    type="button"
                    class="btn-green"
                    @click="close">
                    {{ $t('modals.closeBtn') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "PayPalTransactionsModal",

        components: {

        },

        props: {
          transactions: Array
        },

        methods: {
            close() {
                this.$emit('close');
            },

            getDate(value) {
                return moment(String(value)).format('YYYY-MM-DD HH:mm')
            }
        },
    }
</script>


<style>
    .modal-payments {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        display: block;
        height: 300px;
        left: 0;
        margin: -150px auto 0;
        max-width: 600px;
        overflow-x: auto;
        right: 0;
        top: 50%;
        width: 90%;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }

    .modal-body {
        position: relative;
        padding: 10px 20px;
        max-height: 210px;
        overflow-y: auto;
        min-height: 210px;
    }

    .btn-close {
        border: none;
        font-size: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
    }

    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }
</style>
