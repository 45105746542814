<template>
    <div>
        <div class="title">{{ $t('token.transactions.list.title') }}</div>
        <div class="row">
            <div class="col-md-10">
                <form class="form-row" ref="searchForm" @submit.prevent="() => {}">
                    <div class="form-group col-md-3">
                        <label>{{ $t('user.account') }} / {{ $t('user.id') }}</label>
                        <input type="text" class="form-control" v-model="filter.email_or_id">
                    </div>
                    <div class="form-group col-md-2">
                        <label>{{ $t('token.transactions.list.status') }}</label>
                        <select class="form-control" v-model="filter.status">
                            <option></option>
                            <option v-for="(value, name) in $t('token.transactions.statuses')" :value="name" v-bind:key="value">
                                {{ value }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-mod-3 d-flex align-items-end">
                        <div class="btn-group btn-group-toggle">
                            <label class="btn" v-bind:class="{ 'btn-primary': filter.and_search, 'btn-light': !(filter.and_search) }"
                                    @click="handleOnclickJoin(1)">
                                <input type="radio" name="and_search" v-bind:checked="filter.and_search" autocomplete="off"/>AND
                            </label>
                            <label class="btn" v-bind:class="{ 'btn-primary': !(filter.and_search), 'btn-light': filter.and_search }"
                                    @click="handleOnclickJoin(0)">
                                <input type="radio" name="and_search" v-bind:checked="!(filter.and_search)" autocomplete="off" />OR
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <label>{{ $t('token.transactions.list.fromToAddress') }}</label>
                        <input type="text" class="form-control" v-model="filter.address"
                            placeholder="0x0000000000000000000000000000000000000000"
                            pattern="^0[xX][0-9a-fA-F]{40}$"
                        />
                    </div>
                    <div class="form-group col-md-2 search-button">
                        <button type="button" class="btn btn-primary" @click="handleSearch">
                            {{ $t('search') }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <table class="table table-striped fixed-scroll">
            <thead>
                <tr>
                    <th scope="col" class="filter" @click="handleOnclickOrder('id')">
                        {{ $t('token.transactions.list.id') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('id', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('id', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col" class="filter" @click="handleOnclickOrder('to_address')">
                        {{ $t('token.transactions.list.toAddress') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('to_address', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('to_address', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col" class="filter" @click="handleOnclickOrder('address')">
                        {{ $t('token.transactions.list.fromAddress') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('address', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('address', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col" class="filter" @click="handleOnclickOrder('user_id')">
                        {{ $t('user.id') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('user_id', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('user_id', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col" class="filter" @click="handleOnclickOrder('external_user_id')">
                        {{ $t('user.externalId') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('external_user_id', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('external_user_id', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col" class="filter" @click="handleOnclickOrder('email')">
                        {{ $t('user.email') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('email', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('email', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col" class="filter" @click="handleOnclickOrder('amount')">
                        {{ $t('token.transactions.list.amount') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('amount', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('amount', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col" class="filter" @click="handleOnclickOrder('approved_time')">
                        {{ $t('token.transactions.list.approvedTime') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('approved_time', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('approved_time', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col" class="filter" @click="handleOnclickOrder('status')">
                        {{ $t('status') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('status', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('status', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col" class="filter" @click="handleOnclickOrder('processed_admin_time')">
                        {{ $t('token.transactions.list.processedAdminTime') }}
                        <div class="block-with-caret">
                            <div class="arrow-up" v-if="checkOrder('processed_admin_time', 'asc')"></div>
                            <div class="arrow-down" v-if="checkOrder('processed_admin_time', 'desc')"></div>
                        </div>
                    </th>
                    <th scope="col">{{ $t('details') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in list" v-bind:key="item.id">
                    <td scope="row">{{ item.id }}</td>
                    <td>{{ item.to_address }}</td>
                    <td>{{ item.from_address }}</td>
                    <td>{{ item.from_user ? item.from_user.id                  : 'NO DATA' }}</td>
                    <td>{{ item.from_user ? item.from_user.external_user_id    : 'NO DATA' }}</td>
                    <td>{{ item.from_user ? item.from_user.email               : 'NO DATA' }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.approved_time | moment('YYYY/MM/DD HH:mm:ss') }}</td>
                    <td>{{ $t('token.transactions.statuses[' + item.status + ']') }}</td>
                    <td>{{ item.processed_admin_time | moment('YYYY/MM/DD HH:mm:ss') }}</td>
                    <td>
                        <button type="button" class="btn btn-primary" @click="showModal(item)">
                            {{ $t('details') }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <TokenTransactionsModal
            v-show="isShowModal"
            :transaction="transaction"
            @close="closeModal"
        />

        <div class="row">
            <div class="col-sm-12">
                <paginate v-bind:pagination="pagination" v-on:change-page="fetchTokenTransactions"></paginate>
            </div>
        </div>
    </div>
</template>

<script>
    import tokenService from './../services/TokenService';
    import Paginate from '../components/Paginate'
    import TokenTransactionsModal from './TokenTransactionsModal';

    export default {
        name: 'token-transactions',

        components: {
            Paginate,
            TokenTransactionsModal
        },

        data() {
            return {
                list: [],
                pagination: {
                    total_pages: 0,
                    current_page: 1,
                    per_page: 10,
                    total: 0
                },
                filter: {
                    email_or_id: '',
                    status: '',
                    order: '',
                    direction: '',
                    address: '',
                    and_search: 0
                },
                isShowModal: false,
                transaction: {}
            }
        },

        async created() {
            await this.fetchTokenTransactions();
        },

        methods: {
            async handleSearch() {
                try {
                    if (! this.$refs.searchForm.checkValidity()) {
                        this.$refs.searchForm.reportValidity();
                        return;
                    }

                    this.pagination.current_page = 1;

                    await this.fetchTokenTransactions();

                    if (this.pagination.total === 0) {
                        window.alert(this.$i18n.t('token.transactions.list.notFound'));
                    }
                } catch (err) {
                    if (err.response.status !== 400) {
                        throw err;
                    }
                }
            },

            setListArray (data = []) {
                this.list = [];
                for (let i in data) {
                    if (data.hasOwnProperty(i) && i !== 'meta') {
                        this.list.push(data[i]);
                    }
                }
            },

            async fetchTokenTransactions() {
                this.list = [];
                let params = {...this.filter};
                params.email_or_id = encodeURIComponent(params.email_or_id);
                params.page = this.pagination.current_page;
                params.size = this.pagination.per_page;
                const list = await tokenService.getTransactions(params);
                this.setListArray(list.data);
                this.pagination = list.data.meta.pagination;
            },

            async refresh(callback = null) {
                await this.fetchTokenTransactions();

                if (callback) {
                    callback();
                }
            },

            handleOnclickOrder(columnName) {
                if (this.filter.order === columnName) {
                    this.filter.direction = this.filter.direction === 'asc' ? 'desc' : 'asc'
                } else {
                    this.filter.order = columnName;
                    this.filter.direction = 'desc'
                }
                this.fetchTokenTransactions();
            },

            checkOrder(columnName, direction) {
                if (columnName !== this.filter.order) {
                    return true;
                } else {
                    return direction === this.filter.direction;
                }
            },

            handleOnclickJoin(value) {
                this.$set(this.filter, "and_search", value);
            },

            async showModal(tokenTransaction) {
                this.transaction = tokenTransaction;
                this.isShowModal = true;
            },

            closeModal(refresh = false) {
                if (refresh) {
                    this.refresh(() => {
                        this.isShowModal = false;
                    });
                } else {
                    this.isShowModal = false;
                }
            }
        },
    }
</script>

<style scoped>
    .filter {
        cursor: pointer;
        white-space: nowrap;
    }

    .filter .block-with-caret {
        display: inline-block;
        line-height: 1;
        font-size: 0;
        margin-left: 4px;
        vertical-align: middle;
        white-space: normal;
        width: 10px;
    }

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #000;
        margin: 1px 0;
        padding-top: 1px;
    }

    .arrow-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #000;
        margin: 1px 0;
    }

    .fixed-scroll {
        display: block;
        overflow-x: scroll;
        white-space: nowrap;
    }
</style>
