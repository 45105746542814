import axios from 'axios'
import config from './../../config';
import AuthUtil from './AuthUtil';

const methods = {
    POST: 'post',
    PUT: 'put',
    GET: 'get'
};

const callbacks = [];
const requests = [];

function processPromise(promise) {
    new Promise(()=>{
        requests.push(promise);
        promise.finally(() => {
            requests.splice(requests.indexOf(promise), 1);
            doCallbacks();
        });
        doCallbacks();
    });
    return promise;
}

function doCallbacks() {
    callbacks.map(callback => callback(requests));
}

export default new class {

    post(uri, data, spinner) {
        return this.request(uri, methods.POST, data, spinner);
    }

    put(uri, data, spinner) {
        return this.request(uri, methods.PUT, data, spinner);
    }

    get(uri, data, spinner) {
        let params = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (data[key] !== null) {
                    params.push(key + '=' + data[key]);
                }
            }
        }
        if (params.length > 0) {
            uri += '?' + params.join('&');
        }

        return this.request(uri, methods.GET, {}, spinner);
    }

    async request(uri, method, data, spinner) {
        spinner = spinner !== false;

        const instance = axios.create({
            baseURL: config.serverUrl,
            headers: {'Authorization': AuthUtil.getAuthDataStr()}
        });

        const promise = new Promise(async (success, rej) => {
            instance.request({
                url: uri,
                method: method,
                data: data ? data : {}
            })
                .then((data) => {
                    success(data);
                    return data;
                })
                .catch((err, data) => {
                    if (err && err.response && err.response.data &&
                        (err.response.data === 'YOU_ARE_NOT_AUTHORIZED' || err.response.data.message === 'YOU_ARE_NOT_AUTHORIZED')
                    ) {
                        AuthUtil.clearAuth();
                        window.location.reload();
                        window.location = window.location;
                    } else {
                        rej(err);
                        throw err;
                    }
                });
        });

        return spinner ? processPromise(promise) : promise;
    }

    onChangeRequests(callback) {
        callbacks.push(callback);
    }

}
