<template>
    <div>
        <div class="title">{{ $t('payments.listPayPalContacts') }}</div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">{{ $t('user.externalId') }}</th>
                    <th scope="col">{{ $t('user.force') }}</th>
                    <th scope="col">{{ $t('user.email') }}</th>
                    <th scope="col">{{ $t('user.first') }}</th>
                    <th scope="col">{{ $t('user.family') }}</th>
                    <th scope="col">{{ $t('usageDuration') }}</th>
                    <th scope="col">{{ $t('payPal.subscriptions.startDate') }}</th>
                    <th scope="col">{{ $t('payments.status') }}</th>
                    <th scope="col">{{ $t('details') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr  v-for="item in list">
                    <td>{{ item.user_external_id }}</td>
                    <td>{{ item.forceClub ? item.forceClub.force_club_id : '' }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.first_name }}</td>
                    <td>{{ item.last_name }}</td>
                    <td>{{ item.usage_duration }} {{$t('days')}}</td>
                    <td>{{ item.start_time | moment('YYYY-MM-DD') }}</td>
                    <td>{{ $t(`payPal.subscriptions.statuses[${item.status}]`) }}</td>
                    <td><button
                        type="button"
                        class="btn btn-primary"
                        @click="showModal(item.external_id)"
                    >
                        {{ $t('payments.button') }}
                    </button></td>
                </tr>
            </tbody>
        </table>

        <PayPalTransactionsModal
            v-show="isModalVisible"
            :transactions="transactions"
            @close="closeModal"
        />
    </div>
</template>

<script>
    import payPalService from "../services/PayPalService";
    import PayPalTransactionsModal from "./PayPalTransactionsModal";

    export default {
        name: 'PayPalSubscriptions',

        components: {
            PayPalTransactionsModal
        },

        data() {
            return {
                list: [],
                pagination: {
                    total_pages: 0,
                    current_page: 1,
                    per_page: 100,
                    total: 0,
                },

                isModalVisible: false,
                transactions: [],
                transactionsPagination: {
                    total_pages: 0,
                    current_page: 1,
                    per_page: 999,
                    total: 0,
                }
            }
        },

        created() {
            this.fetchSubscriptions();
        },

        methods: {
            showModal(subscription_external_id) {
                this.fetchTransactions(subscription_external_id);
                this.isModalVisible = true;
            },

            closeModal() {
                this.isModalVisible = false;
            },

            async fetchSubscriptions() {
                const params = {
                    page: this.pagination.current_page,
                    size: this.pagination.per_page,
                };
                const data = (await payPalService.getSubscriptions(params)).data;
                for (let i in data) {
                    if (data.hasOwnProperty(i) && i !== 'meta') {
                        this.list.push(data[i]);
                    }
                }
                this.pagination = data.meta.pagination;
            },

            async fetchTransactions(agreement_id) {
                this.transactions = [];
                const params = {
                    page: this.transactionsPagination.current_page,
                    size: this.transactionsPagination.per_page,
                    agreement_id: agreement_id
                };
                const data = (await payPalService.getTransactions(params)).data;
                for (let i in data) {
                    if (data.hasOwnProperty(i) && i !== 'meta') {
                        this.transactions.push(data[i]);
                    }
                }
                this.transactionsPagination = data.meta.pagination;
            }
        },
    }
</script>

<style>

</style>
