import JSCookie from 'js-cookie';
import moment from 'moment';

export const AUTHORIZER = 1;
export const OPERATOR = 2;
export const APPROVER = 3;
export const SEARCHER = 4;

export default new class {

    constructor() {
        this.authData = null;
        this.cookieName = 'Admin-Authorization';
    }

    isAuth() {
        if (this.authData && this.authData.token && this.authData.expiration > moment().valueOf()) {
            return true;
        } else {
            const authDataStr = JSCookie.get(this.cookieName);
            if (authDataStr) {
                try {
                    const authData = JSON.parse(authDataStr);

                    if (authData && authData.token && authData.expiration > moment().valueOf()) {
                        return true;
                    }
                } catch (e) {}
            }

            return false;
        }
    }

    setAuth(authData) {
        authData.expiration = moment(authData.expiration).valueOf();
        JSCookie.set(this.cookieName, authData);
    }

    getAuth() {
        return JSCookie.get(this.cookieName);
    }
    
    clearAuth() {
        JSCookie.remove(this.cookieName);
    }

    getAuthDataStr() {
        try {
            const authDataStr = JSCookie.get(this.cookieName);
            const authData = JSON.parse(authDataStr);
            return authData.token;
        } catch (e) {
            return '';
        }
    }

}
