import AdminLayout from './AdminLayout.vue';
import UsersList from './modules/UsersList.vue';
import PayPalTransactions from './modules/PayPalTransactions.vue';
import PayPalSubscriptions from './modules/PayPalSubscriptions.vue';
import KYCsList from './modules/KYCsList.vue';
import UserDetails from './modules/UserDetails.vue';
import UserChildList from './modules/UserChildList.vue';
import Dashboard from './modules/Dashboard.vue';
import PageNotFoundModule from "./modules/PageNotFoundModule";
import TokenTransactions from "./modules/TokenTransactions";
import TokenBalancesList from "./modules/token/TokenBalancesList";
import BalanceHistoryList from "./modules/BalanceHistoryList";
import LogList from "./modules/LogList";
import BonusList from "./modules/BonusList";
import RewardList from "./modules/RewardList";
import PayPalPlans from "./modules/PayPalPlans";
import MiningSettings from "./modules/MiningSettings";
import config from '../config';
import SpecialPayout from './modules/SpecialPayout.vue';
import ForthingContracts from './modules/ForthingContracts.vue';
import ErrorModule from './modules/Error.vue';
import { AUTHORIZER, SEARCHER, OPERATOR, APPROVER } from './utils/AuthUtil';
import NewsList from './modules/news/NewsList.vue';

export default [
    {
        path: '/',
        component: AdminLayout,
        children: [
            {
                path: '',
                component: Dashboard
            },
            {
                path: 'error',
                component: ErrorModule
            },
            {
                path: 'users',
                component: UsersList,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        APPROVER,
                        OPERATOR,
                        SEARCHER
                    ],
                }
            },
            {
                path: 'user/:id',
                name: 'user_details',
                component: UserDetails,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        APPROVER,
                        OPERATOR,
                        SEARCHER
                    ],
                }
            },
            {
                path: 'user/:id/child',
                name: 'user_child',
                component: UserChildList,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        APPROVER,
                        OPERATOR,
                        SEARCHER
                    ],
                }
            },

            {
                path: 'paypal/transactions',
                component: PayPalTransactions,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        OPERATOR
                    ],
                }
            },
            {
                path: 'paypal/subscriptions',
                component: PayPalSubscriptions,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        OPERATOR
                    ],
                }
            },
            {
                path: 'paypal/plans',
                component: PayPalPlans,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        OPERATOR
                    ],
                }
            },

            {
                path: 'kycs',
                component: KYCsList,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        APPROVER,
                        OPERATOR,
                        SEARCHER
                    ],
                }
            },
            {
                path: '/token/transactions',
                name: 'tokenTransactions',
                component: TokenTransactions,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        APPROVER,
                        OPERATOR,
                        SEARCHER
                    ],
                }
            },
            {
                path: '/token/balances',
                name: 'tokenBalances',
                component: TokenBalancesList
            },
            {
                path: 'history',
                name: 'history',
                component: BalanceHistoryList
            },
            {
                path: 'log',
                name: 'log',
                component: LogList
            },
            {
                path: 'bonus',
                name: 'bonus',
                component: BonusList
            },
            {
                path: 'reward',
                name: 'reward',
                component: RewardList
            },
            {
                path: 'miningSettings',
                name: 'miningSettings',
                component: MiningSettings
            },
            {
                path: 'monitoring',
                name: 'monitoring',
                beforeEnter() {
                    window.open(config.monitoringUrl, "_blank");
                }
            },
            {
                path: 'payout',
                name: 'payout',
                component: SpecialPayout,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        APPROVER,
                        OPERATOR,
                        SEARCHER
                    ],
                }
            },
            {
                path: 'forthingContracts',
                name: 'forthingContracts',
                component: ForthingContracts,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        APPROVER,
                        OPERATOR,
                        SEARCHER
                    ],
                }
            },
            {
                path: 'news',
                name: 'news',
                component: NewsList,
                meta: {
                    checkedAdminRole: [
                        AUTHORIZER,
                        APPROVER,
                        OPERATOR,
                        SEARCHER
                    ],
                }
            }
        ]
    },
    {
        path: '*',
        component: PageNotFoundModule
    }
]
