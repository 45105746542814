<template>
    <div class="btn-group">
        <button @click="isActiveDropdown = !isActiveDropdown"
                type="button"
                class="btn btn-outline-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
            {{ $t(activeLanguage) }}
        </button>
        <div :class="['dropdown-menu', {'show': isActiveDropdown}]">
            <div v-for="languag in languages"
               :class="['dropdown-item', {active: languag == activeLanguage}]"
               @click="setLocal(languag)">
                {{ $t(languag) }}
            </div>
        </div>
    </div>
</template>

<script>
    import languages from './languages';
    import JSCookie from 'js-cookie';

    export default {
        name: 'language-choice',

        data() {
            return {
                activeLanguage: '',
                isActiveDropdown: false,
                languages,
            }
        },

        methods: {
            setLocal(locale) {
                this.isActiveDropdown = false;
                this.$i18n.locale = locale;
                this.activeLanguage = locale;
                JSCookie.set('app_locale', locale)
            }
        },

        mounted() {
            this.activeLanguage = this.$i18n.locale;
        }
    }
</script>

<style lang="scss">
    .dropdown-item{

    }
</style>
