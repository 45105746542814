<template>
    <div>
        <div class="title">{{ $t('user.child.list.title', {email: 'test@test.com'}) }}</div>

        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col">{{ $t('user.id') }}</th>
                <th scope="col">{{ $t('user.email') }}</th>
                <th scope="col">{{ $t('user.first') }}</th>
                <th scope="col">{{ $t('user.family') }}</th>
                <th scope="col">{{ $t('usageDuration') }}</th>
                <th scope="col">{{ $t('details') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list">
                <th scope="row">{{ item.external_user_id }}</th>
                <td>{{ item.email }}</td>
                <td>{{ item.userData.first_name }}</td>
                <td>{{ item.userData.last_name }}</td>
                <td>{{ item.duration }}</td>
                <td><button class="btn btn-primary">{{ $t('details')}}</button></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import userChildService from './../services/UserChildService.js';

    export default {
        name: 'user-child',

        data() {
            return {
                list: [],
                pagination: {}
            }
        },

        async created() {
            try {
                this.list = [];
                this.pagination = {};

                const list = await userChildService.getList(this.$route.params.id, 1, 200);

                console.log(list.data);
                list.data = list.data || [];
                for (let i in list.data) {
                    if (list.data.hasOwnProperty(i) && i !== 'meta') {
                        this.list.push(list.data[i]);
                    }
                }

                this.pagination = list.data.meta.pagination;
            } catch (e) {
                console.log(JSON.parse(JSON.stringify(e)));
            }
        },

        mounted() {
            console.log('Component mounted.')
        }
    }
</script>

<style>

</style>
