import ServerRequestUtil from '../utils/ServerRequestUtil';

export default new class {

    async getTransactions(params) {
        return ServerRequestUtil.get("/api/admin/token/transactions", {...params});
    }

    async confirmTransaction(params) {
        return ServerRequestUtil.get("/api/admin/token/confirm-transaction", {...params});
    }

    async getBalances(params) {
        return ServerRequestUtil.get("/api/admin/token/balances", {...params});
    }
    
    async getHecDataHistoryListExport(params) {
        return ServerRequestUtil.get("/api/admin/token/historyListCSV", {...params});
    }

}
