<template>
    <div class="modal-payments">
        <div class="modal">
            <div class="modal-header">
                <div class="col-12 mt-2" v-if="!isEditMode">
                    {{ $t('news.details.title') }}
                </div>
                <div class="col-12 mt-2" v-if="isEditMode">
                    {{ $t('news.details.editTitle') }}
                </div>

                <button type="button" class="btn-close" @click="close(false)">x</button>
            </div>

            <div class="modal-body">
                <div class="row" v-if="isAddMode">
                    <div class="col-12 mt-2">
                        <div class="col-md-12 csv-button mb-3">
                            <button v-on:click="handleOnClickRegist()" v-bind:disabled="isProcessing" class="btn btn-primary">{{ $t('news.actions.regist') }}</button>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label>{{ $t('news.details.start_date') }}</label>
                                <Datepicker :language='ja' :format='customFormatter' wrapper-class="uk-inline" :highlighted='highlighted' :input-class='form-control' :typeable='false' v-model="detail_form.start_date"></Datepicker>
                                <p v-if="errors['start_date'] != ''" class="text-danger">{{ $t('news.validation.' + errors['start_date']) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <ul class="nav nav-tabs">
                            <li 
                              v-for="tab in tabs"
                              :key="tab.id"
                              :class="{active: isCurrentTab === tab.id}"
                              class="nav-item"
                              @click="selected(tab.id)"
                            >
                            <button :class="{active: isCurrentTab === tab.id}" class="nav-link" data-bs-toggle="tab" data-bs-target="'#' + tab.id" v-on:click="handleOnChangeTab(tab.id)" type="button" >{{  tab.title }}</button>
                            </li>
                        </ul>
                        <div class="tab-content p-3">
                            <div class="tab-pane" :class="{active: isCurrentTab === 1}" v-if="isCurrentTab === 1">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>{{ $t('news.details.ja_title') }}</label>
                                            <textarea class="form-control span12 title" rows="1" maxLength="256" v-model="detail_form.ja_title" v-on:blur="handleOnLostFocusJaTitle()"></textarea>
                                            <p v-if="errors['ja_title'] != ''" class="text-danger">{{ $t('news.validation.' + errors['ja_title']) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>{{ $t('news.details.ja_text') }}</label>
                                            <textarea class="form-control span12" rows="5" maxLength="2000" v-model="detail_form.ja_text" v-on:blur="handleOnLostFocusJaText()""></textarea>
                                            <p v-if="errors['ja_text'] != ''" class="text-danger">{{ $t('news.validation.' + errors['ja_text']) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" :class="{active: isCurrentTab === 2}" v-if="isCurrentTab === 2">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>{{ $t('news.details.en_title') }}</label>
                                            <textarea class="form-control span12 title" rows="1" maxLength="256" v-model="detail_form.en_title" v-on:blur="handleOnLostFocusEnTitle()"></textarea>
                                            <p v-if="errors['en_title'] != ''" class="text-danger">{{ $t('news.validation.' + errors['en_title']) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>{{ $t('news.details.en_text') }}</label>
                                            <textarea class="form-control span12" rows="5" maxLength="2000" v-model="detail_form.en_text" v-on:blur="handleOnLostFocusEnText()"></textarea>
                                            <p v-if="errors['en_text'] != ''" class="text-danger">{{ $t('news.validation.' + errors['en_text']) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!-- NewsDataEdit Start-->
                <div class="row" v-else>
                    <div class="col-12 mt-2">
                    </div>
                    <div class="col-md-12 csv-button mb-3">
                        <button v-on:click="handleOnClickRegist()" v-bind:disabled="isProcessing" class="btn btn-primary">{{ $t('news.actions.regist') }}</button>
                    </div>
                    <div class="col-md-12">
                        <div class="form-row">
                            <div class="form-group col-md-2">
                                <label>{{ $t('news.details.id') }}</label>
                                <input v-model="detail_form.id" disabled />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-row">
                            <div class="form-group col-md-2">
                                <label>{{ $t('news.details.start_date') }}</label>
                                <Datepicker :language='ja' :format='customFormatter' wrapper-class="uk-inline" :highlighted='highlighted' :input-class='form-control' :typeable='false' v-model="detail_form.start_date"></Datepicker>
                                <p v-if="errors['start_date'] != ''" class="text-danger">{{ $t('news.validation.' + errors['start_date']) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <ul class="nav nav-tabs">
                            <li 
                              v-for="tab in tabs"
                              :key="tab.id"
                              :class="{active: isCurrentTab === tab.id}"
                              class="nav-item"
                              @click="selected(tab.id)"
                            >
                            <button :class="{active: isCurrentTab === tab.id}" class="nav-link" data-bs-toggle="tab" data-bs-target="'#' + tab.id" v-on:click="handleOnChangeTab(tab.id)" type="button" >{{  tab.title }}</button>
                            </li>
                        </ul>
                        <div class="tab-content p-3">
                            <div class="tab-pane" :class="{active: isCurrentTab === 1}" v-if="isCurrentTab === 1">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>{{ $t('news.details.ja_title') }}</label>
                                            <textarea class="form-control span12 title" rows="1" maxLength="256" v-model="detail_form.ja_title" v-on:blur="handleOnLostFocusJaTitle()"></textarea>
                                            <p v-if="errors['ja_title'] != ''" class="text-danger">{{ $t('news.validation.' + errors['ja_title']) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>{{ $t('news.details.ja_text') }}</label>
                                            <textarea class="form-control span12" rows="5" maxLength="2000" v-model="detail_form.ja_text" v-on:blur="handleOnLostFocusJaText()"></textarea>
                                            <p v-if="errors['ja_text'] != ''" class="text-danger">{{ $t('news.validation.' + errors['ja_text']) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" :class="{active: isCurrentTab === 2}" v-if="isCurrentTab === 2">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>{{ $t('news.details.en_title') }}</label>
                                            <textarea class="form-control span12 title" rows="1" maxLength="256" v-model="detail_form.en_title" v-on:blur="handleOnLostFocusEnTitle()"></textarea>
                                            <p v-if="errors['en_title'] != ''" class="text-danger">{{ $t('news.validation.' + errors['en_title']) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>{{ $t('news.details.en_text') }}</label>
                                            <textarea class="form-control span12" rows="5" maxLength="2000" v-model="detail_form.en_text" v-on:blur="handleOnLostFocusEnText()"></textarea>
                                            <p v-if="errors['en_text'] != ''" class="text-danger">{{ $t('news.validation.' + errors['en_text']) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <div class="col-12 mt-2">
                    <button type="button" class="btn-green" @click="close(false)">
                        {{ $t('modals.closeBtn') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Datepicker from 'vuejs-datepicker';
    import {ja} from 'vuejs-datepicker/dist/locale'
    import NewsDataService from '../../services/NewsDataService';

    export default {
        name: 'news-data-detail-modal',
        components: {
            Datepicker
        },

        props: {
            detail_form: {type: Object},
            isAddMode: {type: Boolean},
            isEditMode: {type: Boolean},
            isProcessing: {type: Boolean},
            isCurrentTab: {type: Number}
        },

        data: function () {
            return {
                ja:ja,
                highlighted: {
                    days: [6,0]
                },
                current: 1,
                tabs: [
                    { id: 1, title: '日本語'},
                    { id: 2, title: '英語' }
                ],
                //isCurrentTab: 1,
                errors: this.getDefaultFields(),
                TITLE_LIMIT: 256,
                TEXT_LIMIT: 2000
            };
        },

        methods: {
            //表示項目取得
            getDefaultFields() {
                return {
                    id: '',
                    start_date: '',
                    en_title: '',
                    en_text: '',
                    ja_title: '',
                    ja_text: '',
                };
            },
            //表示項目クリア
            clearDetailForm() {
                this.detail_form.id = '';
                this.detail_form.start_date = '';
                this.detail_form.ja_title = '';
                this.detail_form.ja_text = '';
                this.detail_form.en_title = '';
                this.detail_form.en_text = '';

            },
            //モーダル閉じる
            close(refresh = false) {
                this.clearDetailForm();
                this.errors = this.getDefaultFields();
                this.isCurrentTab = 1;
                this.$emit('close', refresh);
            },
            //datepickerのフォーマッター
            customFormatter(date) {
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                return year + '.' + month + '.' + day;
            },
            //選択Tabのid設定
            selected(id) {
                this.current = id;
            },
            //Tabボタン選択処理
            handleOnChangeTab(id) {
                this.isCurrentTab = id;
            },
            trimDetailForm() {
                this.detail_form.en_title.trim();
                this.detail_form.en_text.trim();
                this.detail_form.ja_title.trim();
                this.detail_form.ja_text.trim();
            },
            //新規追加・更新のパラメータ設定
            makeParams() {
                this.trimDetailForm();
                let params = {...this.detail_form};
                let start_date = '';
                if (typeof(this.detail_form.start_date) == 'string') {
                    start_date = this.detail_form.start_date + ' 00:00:00';
                } else {
                    start_date = this.customFormatter(this.detail_form.start_date) + ' 00:00:00';
                }
                params.start_date = start_date;
                return params;
            },
            //保存ボタン選択処理
            async handleOnClickRegist() {
                try {
                    this.errors = this.getDefaultFields();
                    const params = this.makeParams();
                    if (this.isAddMode) {
                        //新規追加時
                        const result = await NewsDataService.addNewsData(params);
                    } else {
                        //更新時
                        const result = await NewsDataService.updateNewsData(params);
                    }
                    alert(`お知らせ保存実行\n正常終了しました。`);
                    //モーダルを閉じて一覧画面を更新
                    this.close(true);
                } catch (err) {
                    if (err.response.data.errors) {
                        err.response.data.errors.forEach(errItem => {
                            if (errItem.field) {
                                this.errors[errItem.field] = errItem.message[0];
                            }
                        })
                    }
                    alert(`お知らせ保存実行\n異常終了しました。`);
                }
            },
            //半角は1文字、全角は2文字で文字数算出
            stringCount(target) {
                let len = 0;
                for (let i = 0; i < target.length; i++) {
                    (target[i].match(/[ -~]/)) ? len += 1 : len += 2;
                }
                return len;
            },
            truncate(target,limit) {
                let len = 0;
                for (let i = 0; i < target.length; i++) {
                    (target[i].match(/[ -~]/)) ? len += 1 : len += 2;
                    if (len > limit) {
                        return target.substr(0,i);
                    }
                }
                return target;

            },
            //入力制限値を超えているか
            isOverInputLimit(str,limit) {
                const len = this.stringCount(str);
                if (len > limit) {
                    return true;
                }
                return false;
            },
            handleOnLostFocusEnTitle() {
                this.detail_form.en_title = this.truncate(this.detail_form.en_title,this.TITLE_LIMIT);
            },
            handleOnLostFocusJaTitle() {
                this.detail_form.ja_title = this.truncate(this.detail_form.ja_title,this.TITLE_LIMIT);
            },
            handleOnLostFocusEnText() {
                this.detail_form.en_text = this.truncate(this.detail_form.en_text,this.TEXT_LIMIT);
            },
            handleOnLostFocusJaText() {
                this.detail_form.ja_text = this.truncate(this.detail_form.ja_text,this.TEXT_LIMIT);
            }
        },

        watch: {
            detail_form: {
                handler: function() {
                    //入力文字数チェック
                    this.errors = this.getDefaultFields();
                    if (this.isOverInputLimit(this.detail_form.en_title,this.TITLE_LIMIT)) {
                        this.errors['en_title'] = 'TITLE_INPUT_LIMIT_OVER';
                    }
                    if (this.isOverInputLimit(this.detail_form.en_text,this.TEXT_LIMIT)) {
                        this.errors['en_text'] = 'TEXT_INPUT_LIMIT_OVER';
                    }
                    if (this.isOverInputLimit(this.detail_form.ja_title,this.TITLE_LIMIT)) {
                        this.errors['ja_title'] = 'TITLE_INPUT_LIMIT_OVER';
                    }
                    if (this.isOverInputLimit(this.detail_form.ja_text,this.TEXT_LIMIT)) {
                        this.errors['ja_text'] = 'TEXT_INPUT_LIMIT_OVER';
                    }
                    // 必須項目入力確認
                    if ((this.detail_form.start_date != '' || this.detail_form.start_date != null ) && 
                        this.detail_form.en_title.trim() != ''   &&
                        this.detail_form.en_text.trim()  != ''    &&
                        this.detail_form.ja_title.trim() != ''   &&
                        this.detail_form.ja_text.trim()  != ''    && 
                        this.errors['en_title'] == '' &&
                        this.errors['en_text']  == '' &&
                        this.errors['ja_title'] == '' &&
                        this.errors['ja_text']  == ''
                    ) {
                        //すべての必須項目の入力がある場合に保存ボタンを有効にする
                        this.isProcessing = false;
                    } else {
                        //１つでも必須項目が空の場合は保存ボタンを無効にする
                        this.isProcessing = true;
                    }
                },
                //複数項目の監視する場合は設定する
                deep: true
            }
        }
    }
</script>

<style>
    .modal-payments {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        display: block;
        height: 850px;
        left: 0;
        margin: 0 auto;
        max-width: 1000px;
        overflow-x: auto;
        right: 0;
        top: 10%;
        width: 100%;
        transform: translateY(-10%);
        z-index: 2;

    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: block;
    }

    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }

    .modal-body {
        position: relative;
        padding: 10px 20px;
        height: 700px;
        max-height: 800px;
    }

    .btn-close {
        border: none;
        font-size: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
    }

    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }

    .pre-wrap {
        white-space:pre-wrap;
    }

    .table td, .table th {
        padding: 0.3rem;
        vertical-align: middle;
        border-top: 1px solid #dee2e6;
    }

    .select {
        color: rgb(20 184 166);
        background-color: white;
        border: 1px solid rgb(20 184 166);
    }

    .textarea {
        overflow: hidden !important;
        width: 500px;
    }

    .title {
        padding: 10px;
        line-height: 1.5;
        height: 2.0em;
        margin-bottom: 0;
    }
</style>