<template>
    <div>
        <div class="title">{{ $t('mining.urlTitle') }}</div>
        <div class="message text-danger" v-show="isSucceed">{{ $t('mining.success') }}</div>
        <div class="message text-danger" v-show="isFailed">{{ $t('validation.notSamePercentage') }}</div>
        <table class="table form-group">
            <tbody>
                <tr>
                    <td>{{ $t('mining.columns.miner') }}</td>
                    <td>
                        <div class="input-group">
                            <input type="number" class="form-control" name="FORTHING_DISTRIBUTION_MINER_PERCENTAGE" v-bind:disabled="!isEdit" :value="percentages.FORTHING_DISTRIBUTION_MINER_PERCENTAGE" placeholder="0.00" @change="changePercentage">%
                        </div>
                        <p class="text-danger" v-show="errors.FORTHING_DISTRIBUTION_MINER_PERCENTAGE">{{ $t(errors.FORTHING_DISTRIBUTION_MINER_PERCENTAGE) }}</p>
                    </td>
                </tr>
                <tr>
                    <td>{{ $t('mining.columns.normal') }}</td>
                    <td>
                        <div class="input-group">
                            <input type="number" class="form-control" name="FORTHING_DISTRIBUTION_NORMAL_PERCENTAGE" v-bind:disabled="!isEdit" :value="percentages.FORTHING_DISTRIBUTION_NORMAL_PERCENTAGE" placeholder="0.00" @change="changePercentage">%
                        </div>
                        <p class="text-danger" v-show="errors.FORTHING_DISTRIBUTION_NORMAL_PERCENTAGE">{{ $t(errors.FORTHING_DISTRIBUTION_NORMAL_PERCENTAGE) }}</p>
                    </td>
                </tr>
                <tr>
                    <td>{{ $t('mining.columns.practice') }}</td>
                    <td>
                        <div class="input-group">
                            <input type="number" class="form-control" name="FORTHING_DISTRIBUTION_PRACTICE_PERCENTAGE" v-bind:disabled="!isEdit" :value="percentages.FORTHING_DISTRIBUTION_PRACTICE_PERCENTAGE" placeholder="0.00" @change="changePercentage">%
                        </div>
                        <p class="text-danger" v-show="errors.FORTHING_DISTRIBUTION_PRACTICE_PERCENTAGE">{{ $t(errors.FORTHING_DISTRIBUTION_PRACTICE_PERCENTAGE) }}</p>
                    </td>
                </tr>
                <tr>
                    <td>{{ $t('mining.columns.holderBonus') }}</td>
                    <td>
                        <div class="input-group">
                            <input type="number" class="form-control" name="FORTHING_DISTRIBUTION_HOLDER_BONUS_PERCENTAGE" v-bind:disabled="!isEdit" :value="percentages.FORTHING_DISTRIBUTION_HOLDER_BONUS_PERCENTAGE" placeholder="0.00" @change="changePercentage">%
                        </div>
                        <p class="text-danger" v-show="errors.FORTHING_DISTRIBUTION_HOLDER_BONUS_PERCENTAGE">{{ $t(errors.FORTHING_DISTRIBUTION_HOLDER_BONUS_PERCENTAGE) }}</p>
                    </td>
                </tr>
                <tr>
                    <td>{{ $t('mining.columns.titleBonus') }}</td>
                    <td>
                        <div class="input-group">
                            <input type="number" class="form-control" name="FORTHING_DISTRIBUTION_TITLE_BONUS_PERCENTAGE" v-bind:disabled="!isEdit" :value="percentages.FORTHING_DISTRIBUTION_TITLE_BONUS_PERCENTAGE" placeholder="0.00" @change="changePercentage">%
                        </div>
                        <p class="text-danger" v-show="errors.FORTHING_DISTRIBUTION_TITLE_BONUS_PERCENTAGE">{{ $t(errors.FORTHING_DISTRIBUTION_TITLE_BONUS_PERCENTAGE) }}</p>
                    </td>
                </tr>
                <tr>
                    <td>{{ $t('mining.columns.total') }}</td>
                    <td class="percentage">{{ total }}%</td>
                </tr>
            </tbody>
        </table>

        <button type="button" class="btn btn-default" v-on:click="isEdit = !isEdit">{{ $t('mining.button.edit') }}</button>
        <button type="button" class="btn btn-primary" v-bind:disabled="!isEdit" v-on:click="updateDistributionPercentages">{{ $t('mining.button.save') }}</button>
    </div>
</template>

<script>
    import forthingService from './../services/ForthingService';

    export default {
        name: 'MiningSettings',

        data() {
            return {
                percentages: {},
                total: 0,
                isEdit: false,
                isSucceed: false,
                isFailed: false,
                errors: {}
            }
        },

        async created() {
            const hash = this.$route.query.hash;
            if (hash) {
                await this.approvalDistributionPercentages(hash);
            }
            this.getDistributionPercentages();
        },

        methods: {
            /**
             * マイニング割当を取得する
             */
            async getDistributionPercentages() {
                const data = (await forthingService.getDistributionPercentages()).data;
                this.percentages = data.distribution_percentages;
                this.sumPercentages();
            },
            /**
             * マイニング割当を確定する
             * @param string hash
             */
            async approvalDistributionPercentages(hash) {
                this.isSucceed = false;
                this.isFailed = false;
                try {
                    await forthingService.approvalDistributionPercentages(hash);
                    this.isSucceed = true;
                } catch (e) {
                    this.isFailed = true;
                }
            },
            /**
             * 各割合を計算する
             */
            sumPercentages() {
                this.total = 0;
                for (let percentage in this.percentages) {
                    if (this.percentages[percentage] !== null) {
                        let val = parseFloat(this.percentages[percentage]);
                        if (!isNaN(val)) {
                            this.total += val;
                        }
                    }
                }
                this.total = Math.floor(this.total * 100) / 100;
            },
            /**
             * 入力値変更
             * @param event event
             */
            changePercentage(event) {
                let val = parseFloat(event.target.value);
                this.percentages[event.target.name] = val;
                this.sumPercentages();
            },
            /**
             * マイニング割当を更新する
             */
            async updateDistributionPercentages() {
                const errors = (await forthingService.updateDistributionPercentages(this.percentages)).data;
                this.errors = {};
                for (let col in errors) {
                    for (let index in errors[col]) {
                        this.errors[col] = errors[col][index];
                    }
                }
            }
        }
    }
</script>

<style>
    .title {
        margin-bottom: 30px;
    }
    .message {
        font-weight: bold;
        font-size: large;
        margin-bottom: 10px;
    }
</style>
