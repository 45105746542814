import ServerRequestUtil from '../utils/ServerRequestUtil';

export default new class {

    async getDistributionPercentages() {
        return ServerRequestUtil.get('/api/admin/forthing/distributions/percentages');
    }

    async updateDistributionPercentages(percentages) {
        return ServerRequestUtil.post('/api/admin/forthing/distributions/updatePercentages', percentages);
    }

    async approvalDistributionPercentages(hash) {
        return ServerRequestUtil.get('/api/admin/forthing/distributions/approvalPercentages?hash=' + hash);
    }
}
