import ServerRequestUtil from '../utils/ServerRequestUtil';
import AuthUtil from '../utils/AuthUtil';

export default new class {

    async login(account, password, member) {
        return ServerRequestUtil.post('/api/admin/auth/login', {
            account,
            password,
            member
        });
    }

    setLogin(authData) {
        AuthUtil.setAuth(authData);
    }

    logout() {
        AuthUtil.clearAuth();
    }

}
