import ServerRequestUtil from '../utils/ServerRequestUtil';

export default new class {

    /** 払い出し元ウォレット取得 */
    getWallets(params) {
        return ServerRequestUtil.get("/api/admin/payout/wallets", {...params});
    }

    /** 特別払い出しリスト取得 */
    getSpecialPayout(params) {
        return ServerRequestUtil.get("api/admin/payout/");
    }

    /** CSVアップロード */
    postCsv(data) {
        let formData = new FormData();
        for(let key in data) {
            if(data.hasOwnProperty(key)){
                formData.append(key, data[key]);
            }
        }
        return ServerRequestUtil.post("/api/admin/payout/post-csv", formData);
    }

    /** キャンセル */
    cancel() {
        return ServerRequestUtil.put("/api/admin/payout/cancel");
    }

    /** 論理層実行 */
    executeLogicalLayer() {
        return ServerRequestUtil.put("/api/admin/payout/logical-layer");
    }

    /** 物理層実行 */
    executePhysicalLayer() {
        return ServerRequestUtil.put("/api/admin/payout/physical-layer");
    }
}
