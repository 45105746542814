<template>
    <div>
        <div class="title">{{ $t('reward.list.title') }}</div>
        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col">{{ $t('reward.list.number') }}</th>
                <th scope="col">{{ $t('reward.list.eduTekId') }}</th>
                <th scope="col">{{ $t('reward.list.fullName') }}</th>
                <th scope="col">{{ $t('reward.list.tokenOwnershipAmount') }}</th>
                <th scope="col">{{ "-" }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row">1</th>
                <td>XXXXXXXXXX</td>
                <td>Constantine Levchenko</td>
                <td>XXX, XXX, XXX HEC</td>
                <td><button
                    type="button"
                    class="btn btn-primary"
                    @click=""
                >
                    {{ $t('reward.list.details') }}
                </button></td>
            </tr>
            <tr>
                <th scope="row">2</th>
                <td>XXXXXXXXXX</td>
                <td>Constantine Levchenko</td>
                <td>XXX, XXX, XXX HEC</td>
                <td><button
                    type="button"
                    class="btn btn-primary"
                    @click=""
                >
                    {{ $t('reward.list.details') }}
                </button></td>
            </tr>
            <tr>
                <th scope="row">3</th>
                <td>XXXXXXXXXX</td>
                <td>Constantine Levchenko</td>
                <td>XXX, XXX, XXX HEC</td>
                <td><button
                    type="button"
                    class="btn btn-primary"
                    @click=""
                >
                    {{ $t('reward.list.details') }}
                </button></td>
            </tr>
            <tr>
                <th scope="row">4</th>
                <td>XXXXXXXXXX</td>
                <td>Constantine Levchenko</td>
                <td>XXX, XXX, XXX HEC</td>
                <td><button
                    type="button"
                    class="btn btn-primary"
                    @click=""
                >
                    {{ $t('reward.list.details') }}
                </button></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'withdrawal-list',
        mounted() {

        }
    }
</script>

<style>

</style>
