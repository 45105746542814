<template>
    <div>
        <ul class="pagination">
            <li :class="{disabled: pagination.current_page <= 1}"><a class="page-link" @click="handleOnClickFirstPage()">|&lt;</a></li>
            <li :class="{disabled: pagination.current_page <= 1}"><a class="page-link" @click="handleOnClickPreviousPage()">&lt;</a></li>
            <li v-for="page in pages" :key="page" :class="{active: page === pagination.current_page}">
                <a class="page-link" @click="handleOnClickTargetPage(page)">{{ page }}</a>
            </li>
            <li :class="{disabled: pagination.current_page >= pagination.total_pages}"><a class="page-link" @click="handleOnClickNextPage()">&gt;</a></li>
            <li :class="{disabled: pagination.current_page >= pagination.total_pages}"><a class="page-link" @click="handleOnClickLastPage()">&gt;|</a></li>
        </ul>
        <div class="text-center mt-1">全 {{ pagination.total }} 件中 {{ pagination.from }} 〜 {{ pagination.to }} 件表示</div>
    </div>
</template>

<script>
    export default {
        name: 'paginate',

        props: {
            pagination: Object,
        },

        methods: {
            calcFromTo() {
                if (this.pagination.total_pages < this.pagination.current_page) {
                    return
                }
                this.pagination.from = (this.pagination.current_page - 1) * this.pagination.per_page + 1
                this.pagination.to = this.pagination.current_page * this.pagination.per_page
                if (this.pagination.to > this.pagination.total) {
                    this.pagination.to = this.pagination.total
                }
            },

            handleOnClickFirstPage() {
                if (this.pagination.current_page <= 1) {
                    return
                }
                this.pagination.current_page = 1
                this.$emit('change-page')
            },

            handleOnClickPreviousPage() {
                if (this.pagination.current_page <= 1) {
                    return
                }
                this.pagination.current_page--
                this.$emit('change-page')
            },

            handleOnClickTargetPage(page) {
                if (this.pagination.current_page === page) {
                    return
                }
                this.pagination.current_page = page
                this.$emit('change-page')
            },

            handleOnClickNextPage() {
                if (this.pagination.current_page >= this.pagination.total_pages) {
                    return
                }
                this.pagination.current_page++
                this.$emit('change-page')
            },

            handleOnClickLastPage() {
                if (this.pagination.current_page >= this.pagination.total_pages) {
                    return
                }
                this.pagination.current_page = this.pagination.total_pages
                this.$emit('change-page')
            }
        },

        computed: {
            pages() {
                this.calcFromTo()
                let start = (this.pagination.current_page - 2) > 1 ? this.pagination.current_page - 2 : 1
                let end
                if ((start + 4) < this.pagination.total_pages) {
                    end = start + 4
                } else {
                    end = this.pagination.total_pages
                    if ((end - 4) < start) {
                        start = (end - 4) > 1 ? (end - 4) : 1
                    }
                }
                let pages = []
                for (let i = start; i <= end; i++) {
                    pages.push(i)
                }
                return pages
            }
        }
    }
</script>

<style scoped>
    .pagination .active .page-link {
        z-index: 1;
        color: #fff;
        background-color: #3490dc;
        border-color: #3490dc;
    }
</style>
