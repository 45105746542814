<template>
    <div>
        <global-spinner/>
        <div v-if="!isAuth">
            <auth-login></auth-login>
        </div>
        <div v-if="isAuth">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import AuthLogin from "./AuthLogin";
    import AuthUtil from '../utils/AuthUtil';
    import GlobalSpinner from './../components/GlobalSpinner/GlobalSpinner.vue';

    export default {
        name: 'admin-module',

        components: {
            AuthLogin,
            GlobalSpinner
        },

        data() {
          return {
              isAuth: false
          }
        },

        mounted() {
            this.isAuth = AuthUtil.isAuth();
        }
    }
</script>

<style>

</style>
