<template>
    <div>
        Dashboard
    </div>
</template>

<script>
    export default {
        name: 'dashboard',
        mounted() {

        }
    }
</script>

<style>

</style>
