<template>
    <div class="main">
        <div class="title">{{ $t('news.list.title') }}</div>
        <div class="row" v-if="isListMode">
            <div class="col-md-10 font-weight-bold">{{ $t('news.list.listTitle') }}
            </div>
            <div class="col-md-2 csv-button">
                <button v-on:click="handleOnClickAddMode()" class="btn btn-primary">{{ $t('news.actions.newAddtion') }}</button>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-10 font-weight-bold" v-if="!isEditMode">{{ $t('news.details.title') }}
            </div>
            <div class="col-md-10 font-weight-bold" v-else="isEditMode">{{ $t('news.details.editTitle') }}
            </div>
            <div class="col-md-2 csv-button">
                <button v-on:click="handleOnClickRegist()" v-bind:disabled="isProcessing" class="btn btn-primary">{{ $t('news.actions.regist') }}</button>
            </div>
        </div>
<!-- NewsList Start -->
        <div class="list-table" v-if="isListMode">
            <table class="table table-striped ">
                <thead>
                    <tr>
                        <th scope="col">
                            {{ $t('news.list.id') }}
                            <div class="block-with-caret">
                            </div>
                        </th>
                        <th scope="col">
                            {{ $t('news.list.startDate') }}
                            <div class="block-with-caret">
                            </div>
                        </th>
                        <th scope="col">
                            {{ $t('news.list.newsTitle') }}
                            <div class="block-with-caret">
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in list" v-bind:class="{selected:activeItem===item} ">
                        <th scope="row" >{{ item.id }}</th>
                        <td>{{ item.start_date }}</td>
                        <td class="wrap-td"><p>{{ item.title }}</p></td>
                        <td>
                            <button type="button" class="btn btn-primary" v-on:click="handleOnClickDetail(item)">
                                {{ $t('details') }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <div class="row mt-2">-->
        <!-- <div class="col-md-12">-->
        <div class="row" v-if="isListMode">
            <div class="col-sm-12">
                <div class="form-group float-left">
                    <paginate v-bind:pagination="pagination" v-on:change-page="fetchNewsDataList" v-if="pagination.total"></paginate>
                </div>
            </div>
        </div>

    <NewsDataDetailModal
            v-show="isShowModal"
            :detail_form="detail_form"
            :isAddMode="isAddMode"
            :isEditMode="isEditMode"
            :isProcessing="isProcessing"
            :isCurrentTab=1
            @close="closeModal"
        />

    </div>

</template>

<script>
    import NewsDataService from './../../services/NewsDataService';
    import NewsDataDetailModal from './NewsDataDetailModal';
    import Paginate from './../../components/Paginate';

    export default {
        name: 'news-list',

        components: {
            Paginate,
            NewsDataDetailModal
        },

        data() {
            return {
                isShowModal: false,
                isListMode: true,
                isEditMode: false,
                isAddMode: false,
                isProcessing: true,
                details: [],
                isOpenSearch : false,
                list: [],
                pagination: {
                    total_pages: 0,
                    current_page: 1,
                    per_page: 10,
                    total: 0
                },
                isShowDetail: false,
                item: {
                    news: {
                        newsData: {
                        }
                    },
                },
                activeItem : '',
                detail_form: {
                    id: '',
                    start_date: '',
                    ja_title: '',
                    ja_text: '',
                    en_title: '',
                    en_text: ''
                },
            }
        },

        created: function () {
            this.fetchNewsDataList();
        },


        watch: {
            item(values) {
                this.setForm(this.item);
            },
        },

        methods: {
            //新規追加ボタン押下
            handleOnClickAddMode() {
                this.isAddMode = !this.isAddMode;
                this.isEditMode = false;
                this.isProcessing = true;
                this.clearDetailForm();
                this.showModal();
            },
            async handleSearch() {
                this.pagination.current_page = 1;
                await this.fetchNewsDataList();
            },

            setListArray(data = []) {
                this.list = [];
                for (let i in data) {
                    if (data.hasOwnProperty(i) && i !== 'meta') {
                        this.list.push(data[i]);
                    }
                }
            },
            //検索用パラメータ設定
            makeParams() {
                let params = {...this.pagination};
                params.page = this.pagination.current_page;
                params.size = this.pagination.per_page;
                params.order = '';
                params.locale = 'ja_JP';
                return params;
            },
            //一覧用表示データ取得
            async fetchNewsDataList() {
                this.isShowDetail = false;
                this.list = [];
                const params = this.makeParams();
                const list = await NewsDataService.getNewsDataList(params);
                this.setListArray(list.data);
                this.pagination = list.data.meta.pagination;
            },
            //モーダル用表示データ初期化
            clearDetailForm() {
                this.detail_form.id = '';
                this.detail_form.start_date = '';
                this.detail_form.ja_title = '';
                this.detail_form.ja_text = '';
                this.detail_form.en_title = '';
                this.detail_form.en_text = '';
                this.detail_form.radio_selected = 'ja-JP';
            },
            //詳細ボタン押下時の処理
            async handleOnClickDetail(item) {
                this.isEditMode = !this.isEditMode;
                const detail = await NewsDataService.getNewsDataDetail(item.id);
                this.details = detail.data[0];
                this.detail_form.id = this.details.id;
                this.detail_form.start_date = this.details.start_date;
                this.detail_form.ja_title = this.details.ja_title;
                this.detail_form.ja_text = this.details.ja_text;
                this.detail_form.en_title = this.details.en_title;
                this.detail_form.en_text = this.details.en_text;
                //更新の場合は保存ボタンを有効化
                this.isProcessing = false;
                this.showModal();
            },
            //モーダル表示
            async showModal() {
                this.isShowModal = true;
            },
            //モーダルを閉じる
            closeModal(refresh = false) {
                if (refresh) {
                    this.refresh(() => {
                        this.isShowModal = false;
                        this.isAddMode = false;
                        this.isEditMode = false;
                    });
                } else {
                    this.isShowModal = false;
                    this.isAddMode = false;
                    this.isEditMode = false;
                }
            },
            //モーダルで新規追加、更新が正常終了の場合に一覧画面へ反映
            async refresh(callback = null) {
                await this.fetchNewsDataList();
                if (callback) {
                    callback();
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .list-table {
        max-height: 700px;
        max-width: 1000px;
        overflow: auto;
        margin-bottom: 50px;
    }

    .display-item-label {
        margin-top: 10px;
        padding-right: 0;
        text-align: center;
    }

    .display-item {
        padding-left: 0;
    }

    .table td, .table th {
        padding: 0.3rem;
        vertical-align: middle;
        border-top: 1px solid #dee2e6;
    }

    .selected{
        background-color: #0072ff38 !important;
    }

    .wrap-td {
        word-wrap: normal;
        white-space: normal;
        max-width: 600px;
    }

    .main {
        max-width: 1000px;
    }

</style>
