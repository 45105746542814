<template>
    <div>
        画面を表示する権限がありません。
    </div>
</template>

<script>
    export default {
        name: 'error',
        mounted() {

        }
    }
</script>

<style>

</style>
