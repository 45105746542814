<template>
    <div class="modal-payments">
        <div class="modal">
            <div class="modal-header">
                <div class="col-12 mt-2">
                    {{ $t('token.transactions.modal.title') }}
                </div>

                <button type="button" class="btn-close" @click="close(false)">x</button>
            </div>

            <div class="modal-body">
                <div class="col-12">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <td>{{ $t('token.transactions.modal.title') }}</td>
                                <td>{{ transaction.id }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.fromAddress') }}</td>
                                <td>{{ transaction.from_address }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.fromUserId') }}</td>
                                <td>{{ transaction.from_user ? transaction.from_user.id : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.fromUserExternalId') }}</td>
                                <td>{{ transaction.from_user ? transaction.from_user.external_user_id : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.fromUserEmail') }}</td>
                                <td>{{ transaction.from_user ? transaction.from_user.email : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.fromUserName') }}</td>
                                <td>{{ transaction.from_user ? `${ transaction.from_user.userData.last_name } ${ transaction.from_user.userData.first_name }` : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.toAddress') }}</td>
                                <td>{{ transaction.to_address }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.toUserId') }}</td>
                                <td>{{ transaction.to_user ? transaction.to_user.id : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.toUserExternalId') }}</td>
                                <td>{{ transaction.to_user ? transaction.to_user.external_user_id : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.toUserEmail') }}</td>
                                <td>{{ transaction.to_user ? transaction.to_user.email : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.toUserName') }}</td>
                                <td>{{ transaction.to_user ? `${ transaction.to_user.userData.last_name } ${ transaction.to_user.userData.first_name }` : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.amount') }}</td>
                                <td>{{ transaction.amount }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.approvedTime') }}</td>
                                <td>{{ transaction.approved_time | moment('YYYY/MM/DD HH:mm:ss') }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('status') }}</td>
                                <td>{{ $t('token.transactions.statuses[' + transaction.status + ']') }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.approvedAdmin') }}</td>
                                <td>
                                    <div class="radio" v-if="controllable()">
                                        <label class="m-0 p-0">
                                            <input type="radio" name="is_approved_admin" value="1" v-model="is_approved" checked>
                                            {{ $t('token.transactions.modal.isApprovedAdmin.ok') }}
                                        </label>
                                        &nbsp;
                                        <label class="m-0 p-0">
                                            <input type="radio" name="is_approved_admin" value="0" v-model="is_approved">
                                            {{ $t('token.transactions.modal.isApprovedAdmin.ng') }}
                                        </label>
                                    </div>
                                    <span v-else>
                                        {{ transaction.is_approved_admin ? $t('token.transactions.modal.isApprovedAdmin.ok') : $t('token.transactions.modal.isApprovedAdmin.ng')}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.reason') }}</td>
                                <td>
                                    <div v-if="controllable()">
                                        <textarea class="form-control" rows="3" v-model="transaction.reason"></textarea>
                                        <div class="mt-3 text-right">
                                            <button type="button" class="btn btn-primary" @click="confirmTransaction()">Submit</button>
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ transaction.reason }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.txHash') }}</td>
                                <td>{{ transaction.tx_hash ? transaction.tx_hash : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.shortTxHash') }}</td>
                                <td>{{ transaction.short_tx_hash ? transaction.short_tx_hash : 'NO DATA' }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('token.transactions.modal.processedAdminTime') }}</td>
                                <td>{{ transaction.processed_admin_time | moment('YYYY/MM/DD HH:mm:ss') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn-green" @click="close(false)">
                    {{ $t('modals.closeBtn') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import tokenService from './../services/TokenService';

    export default {
        name: 'token-transactions-modal',

        components: {
        },

        props: {
            transaction: Object,
        },

        data() {
            return {
                is_approved: 0,
            };
        },

        methods: {
            controllable() {
                return (
                    (this.transaction.status               === '6' /** = STATUS_QUEUED (文字で正しい) */) &&
                    (this.transaction.is_approved          === 1   /** = true  (数字で正しい) */) &&
                    (this.transaction.is_approved_admin    === 0   /** = false (数字で正しい) */) &&
                    (this.transaction.processed_admin_time === null)
                );
            },

            async confirmTransaction() {
                let [ result ] = await Promise.all([
                    tokenService.confirmTransaction({
                        id: this.transaction.id,
                        is_approved_admin: this.is_approved,
                        reason: this.transaction.reason,
                    })
                ]);

                if (result.data.result) {
                    this.close(true);
                }
            },

            close(refresh = false) {
                this.$emit('close', refresh);
            }
        },
    }
</script>


<style>
    .modal-payments {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        display: block;
        height: 500px;
        left: 0;
        margin: 0 auto;
        max-width: 930px;
        overflow-x: auto;
        right: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        border-bottom: 1px solid #eeeeee;
        color: #4AAE9B;
        justify-content: space-between;
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }

    .modal-body {
        position: relative;
        padding: 10px 20px;
        max-height: 380px;
        overflow-y: auto;
    }

    .btn-close {
        border: none;
        font-size: 20px;
        cursor: pointer;
        font-weight: bold;
        color: #4AAE9B;
        background: transparent;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
    }

    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }

    .pre-wrap {
        white-space:pre-wrap;
    }
</style>
