<template>
    <transition name="fade">
        <div class="global-spinner" v-if="show">
            <img class="spinner-img" src="./global-spinner.svg"/>
        </div>
    </transition>
</template>

<script>
    import serverRequestUtil from './../../utils/ServerRequestUtil';

    export default {
        name: 'global-spinner',

        data() {
            return {
                show: false,
                timeoutTime: 4000,
                minTime: 500
            }
        },

        created() {
            serverRequestUtil.onChangeRequests(this.handleOnChangeRequests.bind(this));
        },

        methods: {
            handleOnChangeRequests(requests) {
                (!!requests.length) ?
                    this.showSpinner() :
                    this.hide();
            },

            showSpinner() {
                this.show = true;
                this.timeout();
            },

            hide() {
                if (this.hideTimeout) {
                    clearTimeout(this.hideTimeout);
                    this.hideTimeout = undefined;
                }

                this.hideTimeout = setTimeout(()=>{
                    this.hideTimeout = undefined;
                    this.show = false;
                }, this.minTime)
            },

            timeout() {
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = undefined;
                }

                this.timeoutId = setTimeout(()=>{
                    this.timeoutId = undefined;
                    this.hide();
                }, this.timeoutTime);
            }
        }
    }
</script>

<style lang="scss">
    .global-spinner {
        background-color: rgb(255, 255, 255, 0.75);
        min-width: 100%;
        min-height: 100%;
        position: fixed;
        top: 0;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
