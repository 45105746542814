<template>
    <div>
        <div class="title">{{ $t('balance.history.list.title') }}</div>
        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col">{{ $t('withdrawals.table.id') }}</th>
                <th scope="col">{{ $t('tokens.table.user') }}</th>
                <th scope="col">{{ $t('amount') }}</th>
                <th scope="col">{{ $t('status') }}</th>
                <th scope="col">{{ $t('date') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row">XXX1111</th>
                <td>Takeshi Tanaka</td>
                <td>200 HEC</td>
                <td>Completed</td>
                <td>2020-01-20</td>
            </tr>
            <tr>
                <th scope="row">2222XXX</th>
                <td>Bob Smith</td>
                <td>150 HEC</td>
                <td>Pending</td>
                <td>2019-12-30</td>
            </tr>
            <tr>
                <th scope="row">2222DDD</th>
                <td>Jacob Mayor</td>
                <td>100 HEC</td>
                <td>Failed</td>
                <td>2019-12-25</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'withdrawal-list',
        mounted() {

        }
    }
</script>

<style>

</style>
