<template>
    <div>
        <div class="title">{{ $t('kyc.list.title') }}</div>
        <div class="row">
            <div class="col-md-10">
                <filter-bar :search="handleSearch">
                    <div class="form-group col-md-5">
                        <label>{{ $t('kyc.emailOrId') }}</label>
                        <input type="text" class="form-control" v-model="filter.email_or_force_club_id">
                    </div>
                    <div class="form-group col-md-2">
                        <label>{{ $t('kyc.status') }}</label>
                        <select class="form-control" v-model="filter.status">
                            <option></option>
                            <option v-for="options in statusOptions" :value="options.value">{{ $t(`kyc.list.filter.options.statuses.${options.name}`)}}</option>
                        </select>
                    </div>
                    <div class="form-group col-mod-2 d-flex align-items-end">
                        <div class="btn-group btn-group-toggle">
                            <label class="btn" v-bind:class="{ 'btn-primary': filter.and_search, 'btn-light': !(filter.and_search) }"
                                   @click="handleOnclickJoin(1)">
                                <input type="radio" name="and_search" v-bind:checked="filter.and_search" autocomplete="off"/>AND
                            </label>
                            <label class="btn" v-bind:class="{ 'btn-primary': !(filter.and_search), 'btn-light': filter.and_search }"
                                   @click="handleOnclickJoin(0)">
                                <input type="radio" name="and_search" v-bind:checked="!(filter.and_search)" autocomplete="off" />OR
                            </label>
                        </div>
                    </div>
                </filter-bar>
            </div>
            <div class="col-md-2 csv-button">
                <button @click="handleOnClickDownloadCsv()" download="kycs.csv" class="btn btn-primary">{{ $t('kyc.actions.exportCsv') }}</button>
            </div>
        </div>

        <transition name="js-toggle" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
            <div class="js-toggle--target" v-if="isOpenSearch">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label>{{ $t('kyc.createdAt') }}</label>
                                <Datepicker :language='ja' :format='customFormatter' input-class='form-control' :typeable='true' v-model="filter.from" @closed="pickerClosedFrom"></Datepicker>
                            </div>
                            <div class="form-group hyphen">
                                <span>-</span>
                            </div>
                            <div class="form-group col-md-4">
                                <label>&nbsp;</label>
                                <Datepicker :language='ja' :format='customFormatter' input-class='form-control' :typeable='true' v-model="filter.to" @closed="pickerClosedTo"></Datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label>{{ $t('kyc.firstName') }}</label>
                                <input type="text" class="form-control" v-model="filter.first_name">
                            </div>
                            <div class="form-group col-md-4">
                                <label>{{ $t('kyc.lastName') }}</label>
                                <input type="text" class="form-control" v-model="filter.last_name">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div class="js-toggle" v-cloak>
            <div class="text-center">
                <button type="button" class="js-toggle--trigger btn btn-outline-primary btn-lg w-25" @click="toggleMenu()">
                    <div class="mx-auto" :class="{'arrow-up': isOpenSearch, 'arrow-down': !isOpenSearch}"></div>
                </button>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="form-group col-md-3 row float-right">
                    <div class="col-md-6 display-item-label">
                        <label>{{ $t('kyc.displayItem') }}</label>
                    </div>
                    <div class="col-md-6 display-item">
                        <select class="form-control" v-model="pagination.per_page" v-on:change="handleSearch">
                            <option v-for="option in displayItems" :value="option.value">{{ option.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group float-right">
                    <paginate v-bind:pagination="pagination" v-on:change-page="fetchUserListWithKycSettings" v-if="pagination.total"></paginate>
                </div>
            </div>
        </div>

        <div class="list-table">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="filter" @click="handleOnclickOrder('email')">
                            {{ $t('kyc.email') }}
                            <div class="block-with-caret">
                                <div class="arrow-up" v-if="checkOrder('email', 'asc')"></div>
                                <div class="arrow-down" v-if="checkOrder('email', 'desc')"></div>
                            </div>
                        </th>
                        <th scope="col" class="filter" @click="handleOnclickOrder('force_club_id')">
                            {{ $t('kyc.forceClubId') }}
                            <div class="block-with-caret">
                                <div class="arrow-up" v-if="checkOrder('force_club_id', 'asc')"></div>
                                <div class="arrow-down" v-if="checkOrder('force_club_id', 'desc')"></div>
                            </div>
                        </th>
                        <th scope="col" class="filter" @click="handleOnclickOrder('first_name')">
                            {{ $t('kyc.firstName') }}
                            <div class="block-with-caret">
                                <div class="arrow-up" v-if="checkOrder('first_name', 'asc')"></div>
                                <div class="arrow-down" v-if="checkOrder('first_name', 'desc')"></div>
                            </div>
                        </th>
                        <th scope="col" class="filter" @click="handleOnclickOrder('last_name')">
                            {{ $t('kyc.lastName') }}
                            <div class="block-with-caret">
                                <div class="arrow-up" v-if="checkOrder('last_name', 'asc')"></div>
                                <div class="arrow-down" v-if="checkOrder('last_name', 'desc')"></div>
                            </div>
                        </th>
                        <th scope="col" class="filter" @click="handleOnclickOrder('status')">
                            {{ $t('kyc.status') }}
                            <div class="block-with-caret">
                                <div class="arrow-up" v-if="checkOrder('status', 'asc')"></div>
                                <div class="arrow-down" v-if="checkOrder('status', 'desc')"></div>
                            </div>
                        </th>
                        <th scope="col" class="filter" @click="handleOnclickOrder('application_date')">
                            {{ $t('kyc.createdAt') }}
                            <div class="block-with-caret">
                                <div class="arrow-up" v-if="checkOrder('application_date', 'asc')"></div>
                                <div class="arrow-down" v-if="checkOrder('application_date', 'desc')"></div>
                            </div>
                        </th>
                        <th scope="col" class="filter" @click="handleOnclickOrder('updated_at')">
                            {{ $t('kyc.updatedAt') }}
                            <div class="block-with-caret">
                                <div class="arrow-up" v-if="checkOrder('updated_at', 'asc')"></div>
                                <div class="arrow-down" v-if="checkOrder('updated_at', 'desc')"></div>
                            </div>
                        </th>
                        <th scope="col">{{ $t('details') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in list" v-bind:class="{selected:activeItem===item} ">
                        <th scope="row" >{{ item.user.email }}</th>
                        <td>{{ item.user.force_club_id }}</td>
                        <td>{{ item.user.userData.first_name }}</td>
                        <td>{{ item.user.userData.last_name }}</td>
                        <td>{{ $t('kyc.statuses[' + item.status + ']') }}</td>
                        <td>{{ item.application_date|moment('YYYY-MM-DD') }}</td>
                        <td>{{ item.updated_at|moment('YYYY-MM-DD') }}</td>
                        <td>
                            <button type="button" class="btn btn-primary" @click="showDetail(item)">
                                {{ $t('details') }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h3 id="detail-top" class="title" v-show="isShowDetail">{{ $t('kyc.details.title') }}</h3>
        <div id="detail-block" class="detail-block" v-show="isShowDetail">
            <div class="col-12">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="status">{{ $t('kyc.status') }}</label>
                        <input type="text" class="form-control" id="status" :value="displayForm.statusText" disabled>
                    </div>
                    <div class="form-group col-md-6">
                        <a id="download-link" class="btn btn-primary" :class="{disabled: item.base64 === ''}">
                            {{ $t('kyc.details.saveImage') }}
                        </a>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <div class="form-group">
                            <label for="displayUnapprovedReason1">{{ $t('kyc.details.unapprovedReason1') }}</label>
                            <input type="text" class="form-control" id="displayUnapprovedReason1" :value="getUnapprovedReasonDescription(displayForm.unapproved_reason1)" disabled>
                        </div>
                        <div class="form-group">
                            <label for="displayUnapprovedReason2">{{ $t('kyc.details.unapprovedReason2') }}</label>
                            <input type="text" class="form-control" id="displayUnapprovedReason2" :value="getUnapprovedReasonDescription(displayForm.unapproved_reason2)" disabled>
                        </div>
                        <div class="form-group">
                            <label for="displayUnapprovedReasonText">{{ $t('kyc.details.unapprovedReasonText') }}</label>
                            <textarea id="displayUnapprovedReasonText" class="form-control" rows="3" disabled v-model="displayForm.unapproved_reason_text"></textarea>
                        </div>
                        <div class="form-row form-group">
                            <div class="col-md-6">
                                <label for="userId">{{ $t('kyc.details.userId') }}</label>
                                <input type="text" class="form-control" id="userId" :value="item.user.id" disabled>
                            </div>
                            <div class="col-md-6">
                                <label for="aubeId">{{ $t('kyc.details.aubeId') }}</label>
                                <input type="text" class="form-control" id="aubeId" :value="item.user.external_user_id" disabled>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="forceClubId">{{ $t('kyc.details.forceClubId') }}</label>
                            <input type="text" class="form-control" id="forceClubId" :value="item.user.force_club_id" disabled>
                        </div>
                        <div class="form-group">
                            <label for="email">{{ $t('kyc.details.email') }}</label>
                            <input type="text" class="form-control" id="email" :value="item.user.email" disabled>
                        </div>
                        <div class="form-row form-group">
                            <div class="col-md-6">
                                <label for="firstName">{{ $t('kyc.firstName') }}</label>
                                <input type="text" class="form-control" id="firstName" :value="item.user.userData.first_name" disabled>
                            </div>
                            <div class="col-md-6">
                                <label for="lastName">{{ $t('kyc.lastName') }}</label>
                                <input type="text" class="form-control" id="lastName" :value="item.user.userData.last_name" disabled>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="birthDate">{{ $t('kyc.details.birthDate') }}</label>
                            <input type="text" class="form-control" id="birthDate" :value="item.user.userData.birth_date|moment('YYYY-MM-DD')" disabled>
                        </div>
                        <div class="form-group">
                            <label for="passportNumber">{{ $t('kyc.details.passportNumber') }}</label>
                            <input type="text" class="form-control" id="passportNumber" :value="item.passport_number" disabled>
                        </div>
                        <div class="form-row form-group">
                            <div class="col-md-6">
                                <label for="createdAt">{{ $t('kyc.details.createdAt') }}</label>
                                <input type="text" class="form-control" id="createdAt" :value="item.application_date|moment('YYYY-MM-DD HH:mm:ss')" disabled>
                            </div>
                            <div class="col-md-6">
                                <label for="updatedAt">{{ $t('kyc.details.updatedAt') }}</label>
                                <input type="text" class="form-control" id="updatedAt" :value="displayForm.updated_at|moment('YYYY-MM-DD HH:mm:ss')" disabled>
                            </div>
                        </div>
                        <div class="form-group col-mod-2 d-flex align-items-end change-status">
                            <div class="col-md-12 btn-group btn-group-toggle">
                                <label class="btn" :class="{ 'btn-success': filter.updateStatus, 'btn-light': !(filter.updateStatus) }"
                                       @click="handleOnclickStatus(true)">
                                    <input type="radio" name="status" :checked="filter.updateStatus" autocomplete="off"/>{{ $t('kyc.details.approve') }}
                                </label>
                                <label class="btn" :class="{ 'btn-warning': !(filter.updateStatus), 'btn-light': filter.updateStatus }"
                                       @click="handleOnclickStatus(false)">
                                    <input type="radio" name="status" :checked="!(filter.updateStatus)" autocomplete="off" />{{ $t('kyc.details.unapprove') }}
                                </label>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="unapprovedReason1">{{ $t('kyc.details.unapprovedReason1') }}</label>
                            <select id="unapprovedReason1" class="form-control" v-model="form.unapproved_reason1" :disabled="filter.updateStatus">
                                <option value="">-</option>
                                <option v-for="option in unapprovedReasons" :value="option.value" :selected="option.value === item.unapproved_reason1">
                                    {{ option.description }}
                                </option>
                            </select>
                            <p id="unapproved_reason1_error" class="text-danger"></p>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="unapprovedReason2">{{ $t('kyc.details.unapprovedReason2') }}</label>
                            <select id="unapprovedReason2" class="form-control" v-model="form.unapproved_reason2" :disabled="filter.updateStatus || form.unapproved_reason1 === null || form.unapproved_reason1 === 'E999'">
                                <option value="">-</option>
                                <option v-for="option in filterUnapprovedReasons()" :value="option.value" :selected="option.value === item.unapproved_reason2">
                                    {{ option.description }}
                                </option>
                            </select>
                            <p id="unapproved_reason2_error" class="text-danger"></p>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="unapprovedReasonText">{{ $t('kyc.details.unapprovedReasonText') }}</label>
                            <textarea id="unapprovedReasonText" class="form-control" v-model="form.unapproved_reason_text" rows="3" :disabled="filter.updateStatus || form.unapproved_reason1 !== 'E999'">{{ item.unapproved_reason_text }}</textarea>
                            <p id="unapproved_reason_text_error" class="text-danger"></p>
                        </div>
                        <div class="form-group col-md-12" v-show="!filter.disableSave">
                            <button type="button" class="btn btn-block btn-primary" @click="handleClickUpdate(item.id)">{{ $t('kyc.details.save') }}</button>
                            <p id="other_error" class="text-danger"></p>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <img v-if="item.base64" class="kyc-image" alt="Your KYC document preview" :src="item.base64">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import kycService from './../services/KycService';
    import FilterBar from '../components/FilterBar';
    import Paginate from '../components/Paginate';
    import downloadFileUtil from "../utils/DownloadFileUtil";
    import moment from 'moment';
    import Datepicker from 'vuejs-datepicker';
    import {ja} from 'vuejs-datepicker/dist/locale'

    export default {
        name: 'kycs-list',

        components: {
            FilterBar,
            Paginate,
            Datepicker
        },

        computed: {
            statusApproved: () => '2',
            statusRejected: () => '3',
        },

        data() {
            return {
                isOpenSearch : false,
                list: [],
                pagination: {
                    total_pages: 0,
                    current_page: 1,
                    per_page: 5,
                    total: 0
                },
                filter: {
                    email_or_force_club_id: '',
                    status: '',
                    from: '',
                    to: '',
                    first_name: '',
                    last_name: '',
                    and_search: 1,
                    updateStatus: false,
                    disableSave: false,

                },
                filterOrder: {
                    order: 'id',
                    direction: 'asc'
                },
                statusOptions: [
                    {
                        name: 'approved',
                        value: "2"
                    },
                    {
                        name: 'rejected',
                        value: "3"
                    },
                    {
                        name: 'uploaded',
                        value: "0,1,4"
                    }
                ],
                displayItems: [
                    {
                        name: '5',
                        value: 5
                    },
                    {
                        name: '10',
                        value: 10
                    },
                    {
                        name: '50',
                        value: 50
                    },
                    {
                        name: '100',
                        value: 100
                    },
                    {
                        name: '300',
                        value: 300
                    },
                    {
                        name: 'All',
                        value: 1000000
                    }
                ],
                isShowDetail: false,
                item: {
                    user: {
                        userData: {
                            birth_date: moment()
                        }
                    },
                    application_date: moment()
                },
                unapprovedReasons: [
                    {value: 'E101', description: this.$t('kyc.unapprovedReasons.E101')},
                    {value: 'E201', description: this.$t('kyc.unapprovedReasons.E201')},
                    {value: 'E202', description: this.$t('kyc.unapprovedReasons.E202')},
                    {value: 'E203', description: this.$t('kyc.unapprovedReasons.E203')},
                    {value: 'E204', description: this.$t('kyc.unapprovedReasons.E204')},
                    {value: 'E205', description: this.$t('kyc.unapprovedReasons.E205')},
                    {value: 'E206', description: this.$t('kyc.unapprovedReasons.E206')},
                    {value: 'E207', description: this.$t('kyc.unapprovedReasons.E207')},
                    {value: 'E208', description: this.$t('kyc.unapprovedReasons.E208')},
                    {value: 'E209', description: this.$t('kyc.unapprovedReasons.E209')},
                    {value: 'E210', description: this.$t('kyc.unapprovedReasons.E210')},
                    {value: 'E301', description: this.$t('kyc.unapprovedReasons.E301')},
                    {value: 'E401', description: this.$t('kyc.unapprovedReasons.E401')},
                    {value: 'E999', description: this.$t('kyc.unapprovedReasons.E999')},
                ],
                form: {
                    status: this.statusApproved,
                    unapproved_reason1: null,
                    unapproved_reason2: null,
                    unapproved_reason_text: null,
                },
                displayForm: {
                    statusText: '',
                    unapproved_reason1: null,
                    unapproved_reason2: null,
                    unapproved_reason_text: null,
                    updated_at: moment(),
                },
                activeItem : '',
                ja:ja,

            }
        },

        watch: {
            item(values) {
                this.clearMessage();
                this.filter.updateStatus = false;
                this.filter.disableSave = false;
                if (this.item.status == this.statusApproved) {
                    this.filter.updateStatus = true;
                    this.filter.disableSave = true;
                }
                this.setForm(this.item);
                this.disableDownloadImage();
            },
            // メールアドレス / Force Club IDの全角除去
            'filter.email_or_force_club_id': function (val) {
                this.filter.email_or_force_club_id = val.replace(/[^!-~]/g, '');
            },
            // 名の全角除去
            'filter.first_name': function (val) {
                this.filter.first_name = val.replace(/[^!-~]/g, '');
            },
            // 姓の全角除去
            'filter.last_name': function (val) {
                this.filter.last_name = val.replace(/[^!-~]/g, '');
            },
            // 非承認理由(その他)は指定文字数で強制的に改行
            'form.unapproved_reason_text': function (val) {
                if (val === null) {
                    return;
                }
                const oldValue = val.split(/\r\n|\n/);
                let newValue = [];
                const rowNum = 25;
                for (const row of oldValue) {
                    for (let i = 0; i < row.length / rowNum; i++) {
                        newValue.push(row.substr(i * rowNum, rowNum));
                    }
                    if (row.length === 0) {
                        newValue.push('');
                    }
                }
                this.form.unapproved_reason_text = newValue.join('\n');
            }
        },

        methods: {
            async handleSearch() {
                this.pagination.current_page = 1;
                await this.fetchUserListWithKycSettings();
            },

            setListArray(data = []) {
                this.list = [];
                for (let i in data) {
                    if (data.hasOwnProperty(i) && i !== 'meta') {
                        this.list.push(data[i]);
                    }
                }
            },

            makeParams() {
                let params = {...this.filter};
                params.email_or_force_club_id = encodeURIComponent(params.email_or_force_club_id);
                params.page = this.pagination.current_page;
                params.size = this.pagination.per_page;
                params.order = this.filterOrder.order;
                params.direction = this.filterOrder.direction;
                return params;
            },

            async fetchUserListWithKycSettings() {
                this.isShowDetail = false;
                this.list = [];
                const params = this.makeParams();
                const list = await kycService.getList(params);

                this.setListArray(list.data);
                this.pagination = list.data.meta.pagination;
            },

            handleOnclickOrder(columnName) {
                if (this.filterOrder.order === columnName) {
                    this.filterOrder.direction = this.filterOrder.direction === 'asc' ? 'desc' : 'asc'
                } else {
                    this.filterOrder.order = columnName;
                    this.filterOrder.direction = 'asc'
                }

                this.fetchUserListWithKycSettings();
            },

            checkOrder(columnName, direction) {
                if (columnName !== this.filterOrder.order) {
                    return true;
                } else {
                    return direction === this.filterOrder.direction;
                }
            },

            handleOnclickJoin(value) {
                this.$set(this.filter, 'and_search', value);
            },

            async handleOnClickDownloadCsv() {
                const params = this.makeParams();
                const result = await kycService.export(params);
                downloadFileUtil.download(result.data, 'kycs.csv');
            },

            toggleMenu() {
                this.isOpenSearch = !this.isOpenSearch;
            },

            beforeEnter: function(el) {
                el.style.height = '0';
            },
            enter: function(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            beforeLeave: function(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            leave: function(el) {
                el.style.height = '0';
            },

            showDetail(item) {
                this.item = item;
                this.isShowDetail = true;
                this.activeItem = item;
                // 初回、描画が間に合わないのでwait
                const that = this;
                window.setTimeout(function () {
                    that.scrollToDetail();
                }, 100);
            },

            /**
             * KYCs Detailsの先頭にスクロールする
             */
            scrollToDetail() {
                document.getElementById('detail-block').scrollTop = 0;
                document.getElementById('detail-top').scrollIntoView({ behavior: 'smooth'});
            },

            updateItem(values) {
                if (this.item.id === values.id) {
                    this.item.status = values.status;
                    this.item.updated_at = values.updated_at;
                }
            },

            /**
             * フォームの値をセットする
             * @param item
             */
            setForm(item) {
                this.form.status = item.status;
                this.form.unapproved_reason1 = item.unapproved_reason1;
                this.form.unapproved_reason2 = item.unapproved_reason2;
                this.form.unapproved_reason_text = item.unapproved_reason_text;

                this.displayForm.statusText = this.$t('kyc.statuses.' + item.status);
                this.displayForm.unapproved_reason1 = item.unapproved_reason1;
                this.displayForm.unapproved_reason2 = item.unapproved_reason2;
                this.displayForm.unapproved_reason_text = item.unapproved_reason_text;
                this.displayForm.updated_at = item.updated_at;
            },

            /**
             * 更新
             * @param id
             */
            async handleClickUpdate(id) {
                this.clearMessage();

                // 承認時
                let notifyMessage = {};
                if (this.filter.updateStatus) {
                    this.form.status = this.statusApproved;
                    notifyMessage = {
                        title: this.$t('kyc.details.notifications.approved.title'),
                        text: this.$t('kyc.details.notifications.approved.text')
                    };
                    this.filter.disableSave = true;
                } else {
                    this.form.status = this.statusRejected;
                    notifyMessage = {
                        title: this.$t('kyc.details.notifications.rejected.title'),
                        text: this.$t('kyc.details.notifications.rejected.text')
                    };
                }

                try {
                    const result = await kycService.update(id, this.form);
                    // 戻り値で更新
                    this.setForm(result.data);

                    this.$notify(notifyMessage);

                    // 更新内容をリスト画面に伝える
                    const updateValues = {
                        id: id,
                        status: this.form.status,
                        updated_at: this.displayForm.updated_at,
                    };
                    this.updateItem(updateValues);
                } catch (e) {
                    for (const error of e.response.data.errors) {
                        // バリデーションエラー
                        let el = document.getElementById(error.field + '_error');
                        if (el !== null) {
                            el.textContent = this.$t(error.message[0]);
                        } else {
                            // 他のエラー
                            el = document.getElementById('other_error');
                            el.textContent = this.$t(error.message[0]);
                        }
                    }
                    console.error(JSON.parse(JSON.stringify(e)));
                }
            },
            /**
             * メッセージをクリアする
             */
            clearMessage() {
                const targetElementIds = [
                    'unapproved_reason1_error',
                    'unapproved_reason2_error',
                    'unapproved_reason_text_error',
                    'other_error',
                ];
                for (const id of targetElementIds) {
                    document.getElementById(id).textContent = '';
                }
            },
            /**
             * 非承認理由を取得する
             * @param value
             */
            getUnapprovedReasonDescription(value) {
                if (value) {
                    return this.$t('kyc.unapprovedReasons.' + value);
                }
                return '-';
            },

            /**
             * 承認/非承認の変更
             * @param value
             */
            handleOnclickStatus(value) {
                if (this.isApproved()) {
                    return;
                }
                this.$set(this.filter, 'updateStatus', value);
            },

            /**
             * 承認済みかを判定する
             */
            isApproved() {
                return this.item.status == this.statusApproved;
            },

            /**
             * KYC画像保存ボタンの制御を行う
             */
            disableDownloadImage() {
                const el = document.getElementById('download-link');
                el.href = 'javascript:void(0)';
                el.removeAttribute('download');
                el.disabled = true;
                if (this.item.base64 !== '') {
                    el.href = this.item.base64;
                    el.download = this.item.file_name;
                    el.disabled = false;
                }
            },

            filterUnapprovedReasons() {
                return this.unapprovedReasons.filter(function(reason) {
                    return reason.value !== 'E999';
                });
            },

            //datepickerのフォーマッター
            customFormatter(date) {
                return moment(date).format('YYYY-MM-DD')
            },

            //datepickerが閉じられた時に日付をフォーマット（申請日from）
            pickerClosedFrom() {
                if(this.filter.from){
                    this.filter.from = moment(this.filter.from).format('YYYY-MM-DD');
                }
            },

            //datepickerが閉じられた時に日付をフォーマット（申請日to）
            pickerClosedTo() {
                if(this.filter.to){
                    this.filter.to = moment(this.filter.to).format('YYYY-MM-DD');
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .filter {
        cursor: pointer;
        white-space: nowrap;
    }

    .filter .block-with-caret {
        display: inline-block;
        line-height: 1;
        font-size: 0;
        margin-left: 4px;
        vertical-align: middle;
        white-space: normal;
        width: 10px;
    }

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #000;
        margin: 1px 0;
        padding-top: 1px;
    }

    .arrow-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #000;
        margin: 1px 0;
    }

    .js-toggle {
        &--target {
            transition: height 0.4s ease-in-out;
        }
        &-enter-active {
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-name: js-toggle--anime__opend;
        }
        &-leave-active {
            animation-duration: 0.4s;
            animation-fill-mode: both;
            animation-name: js-toggle--anime__closed;
        }
    }

    @keyframes js-toggle--anime__opend {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes js-toggle--anime__closed {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .list-table {
        max-height: 400px;
        overflow: auto;
        margin-bottom: 50px;
    }

    .detail-block {
        margin: 0 auto 50px;
        max-height: 800px;
        overflow: auto;
    }

    .change-status {
        margin-top: 50px;
    }

    .kyc-image {
        max-width: 100%;
        height: auto;
    }

    .display-item-label {
        margin-top: 10px;
        padding-right: 0;
        text-align: center;
    }

    .display-item {
        padding-left: 0;
    }

    .hyphen {
        padding-top: 40px;
        width: 20px;
        text-align: center;
    }

    .csv-button {
        display: flex;
        align-items: flex-end;
        margin-bottom:  1rem;
    }

    .table td, .table th {
        padding: 0.3rem;
        vertical-align: middle;
        border-top: 1px solid #dee2e6;
    }

    .selected{
        background-color: #0072ff38 !important;
    }
</style>
