import serverRequestUtil from './../utils/ServerRequestUtil';

export default new class {

    getSubscriptions(params) {
        return serverRequestUtil.get(`api/admin/paypal/subscriptions`, {...params});
    }

    getTransactions(params) {
        return serverRequestUtil.get(`api/admin/paypal/transactions`, {...params});
    }

    getListOfPlans() {
        return serverRequestUtil.post('api/admin/paypal/plans/sync');
    }

    getSubscribersList() {
        return serverRequestUtil.get('api/admin/paypal/plans');
    }
}
