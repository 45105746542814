import ServerRequestUtil from './../utils/ServerRequestUtil';

export default new class {

    getList(params) {
        return ServerRequestUtil.get(`api/admin/users`, {...params});
    }

    getSuspendedList(params) {
        return ServerRequestUtil.get(`api/admin/users/suspend`, {...params});
    }

    getListExport() {
        return ServerRequestUtil.get('api/admin/users/export');
    }

    getUser(id) {
        return ServerRequestUtil.get(`api/admin/user/${id}`);
    }

    suspendUser(id, reason) {
        return ServerRequestUtil.put(`api/admin/user/${id}/suspend`, {comment: reason});
    }

    resumeUser(id) {
        return ServerRequestUtil.put(`api/admin/user/${id}/resume`);
    }

    addHecCardStatusLimit(id, hec_card_status) {
        return ServerRequestUtil.put(`api/admin/user/${id}/addHecCardStatusLimit`, {status: hec_card_status});
    }

    getHecCardStatusList() {
        return ServerRequestUtil.get('api/admin/users/getHecCardStatusList');
    }

    deactivate2sa(user_id) {
        return ServerRequestUtil.post(`api/admin/user/${user_id}/google2saDeactivate`);
    }

    forthingProcedureInfo(user_id) {
        return ServerRequestUtil.get(`api/admin/user/${user_id}/forthingProcedureInfo`);
    }    
}