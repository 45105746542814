<template>
    <div class="pl-3">
        <div class="row">
            <div class="title">{{ $t('payout.specialPayout') }}</div>
        </div>
        <div class="row">
            <div class="col-4 mr-3">    
                <div class="form-group">
                    <!-- 払い出しウォレット選択 -->
                    <label for="fromWallet">{{ $t('payout.fromWallet') }}</label>
                    <select id="fromWallet" class="form-control mb-3" v-model="selectedWallet" :disabled="! enabledSelectWallet">
                        <option :value="null" disabled>{{ $t('payout.requireChoice' )}}</option>
                        <option v-for="wallet in wallets" v-bind:value="wallet" v-bind:key="wallet.key">
                            {{ wallet.name }}
                        </option>
                    </select>
                    <!-- CSVファイル選択 -->
                    <label for="csvPath">{{ $t('payout.selectCsv') }}</label>
                    <div class="input-group mb-3" enctype="multipart/form-data">
                        <input type="text" readonly class="form-control" aria-describedby="button-addon2" :value="csvFileName">
                        <div class="input-group-append">
                            <label class="btn btn-secondary" :class="{ disabled: ! enabledSelectCsv }">{{ $t('payout.reference') }}
                                <input ref="fileComponent" type="file" accept=".csv" class="file-btn" :disabled="! enabledSelectCsv"
                                       @change="handleOnChangeFileInput"/>
                            </label>
                        </div>
                    </div>
                    <!-- 払い出し処理 -->
                    <label for="payoutOperation">{{ $t('payout.payoutOperation') }}</label>
                    <div id="payoutOperation">
                        <button class="btn btn-primary mb-3" :disabled="! enabledLogicalLayer" @click="executeLogicalLayer">{{ $t('payout.executeLogicalLayer') }}</button>
                        <button class="btn btn-primary" :disabled="! enabledPhysicalLayer" @click="executePhysicalLayer">{{ $t('payout.executePhysicalLayer') }}</button>
                    </div>
                    <!-- 取り消し -->
                    <button class="btn btn-secondary mt-5" @click="cancel" :disabled="! enabledCancel">{{ $t('payout.cancel') }}</button>
                </div>
            </div>
            <div class="col-8 row border-left">
                <!-- 払い出しウォレット -->
                <dl class="col-6">
                    <dt>{{ $t('payout.fromWallet') }}</dt>
                    <dd class="ml-3 wallet-address">{{ uploadedFromWalletLabel }}</dd>
                </dl>
                <!-- アップロード済CSV -->
                <dl class="col-6 float-left">
                    <dt>{{ $t('payout.uploadedCsv') }}</dt>
                    <dd class="ml-3 file-name">{{ uploadedCsvFileName }}</dd>
                </dl>
                <!-- CSVエラーチェック結果 -->
                <dl class="col-12">
                    <dt>{{ $t('payout.checkResult') }}</dt>
                    <dd>
                        <!-- レコード件数 -->
                        <ul class="list-group list-group-horizontal list-group-flush">
                            <li class="list-group-item border-bottom-0">{{ $t('payout.allRecordsCount', {count: allCount}) }}</li>
                            <li class="list-group-item border-bottom-0 border-top-0 text-success">{{ $t('payout.okCount', {count: okCount}) }}</li>
                            <li class="list-group-item border-bottom-0 border-top-0 text-danger">{{ $t('payout.ngCount', {count: ngCount}) }}</li>
                            <li class="list-group-item border-bottom-0 border-top-0">{{ $t('payout.processedLogicalLayerCount', {count: logicalLayerCount}) }}</li>
                        </ul>
                        <!-- エラーレコードリスト -->
                        <div class="check-result">
                            <table v-if="csvNgRecords.length > 0" class="table table-striped mb-3">
                                <thead>
                                    <tr>
                                        <th class="sticky-top bg-white">{{ $t('payout.error') }}</th>
                                        <th class="sticky-top bg-white">{{ $t('payout.email') }}</th>
                                        <th class="sticky-top bg-white">{{ $t('payout.force_club_id') }}</th>
                                        <th class="sticky-top bg-white">{{ $t('payout.address') }}</th>
                                        <th class="sticky-top bg-white">{{ $t('payout.amount') }}</th>
                                        <th class="sticky-top bg-white">{{ $t('payout.remarks') }}</th>
                                        <th class="sticky-top bg-white">{{ $t('payout.type') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="r in csvNgRecords" v-bind:key="r.id">
                                        <td class="text-danger">{{ r.error_reason }}</td>
                                        <td>{{ r.email }}</td>
                                        <td>{{ r.force_club_id }}</td>
                                        <td>{{ r.address }}</td>
                                        <td>{{ r.amount }}</td>
                                        <td>{{ r.remarks }}</td>
                                        <td>{{ r.type }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </dd>
                </dl>
                <!-- チェック結果ダウンロード実行 -->
                <div class="col-12 cleafix">
                    <vue-json-to-csv
                        :json-data="verifyResult" :csv-title="$t('payout.verifyResult')">
                        <button class="btn btn-primary float-right" :disabled="! enabledExportCsv">{{ $t('payout.downloadCheckResult') }}</button>
                    </vue-json-to-csv>
                </div>
           </div>
        </div>
    </div>
</template>
<script>
    import payoutService from './../services/PayoutService';
    import VueJsonToCsv from 'vue-json-to-csv';

    export default {
        components: {
            VueJsonToCsv,
        },

        name: 'special-payout',

        data() {
            return {
                formData: {
                    file: null,
                    fromWalletKey: '',
                },
                csvRecords: [],
                wallets: [],
                selectedWallet: null,
            }
        },
        
        async mounted() {
            this.fetch();
        },

        methods: {
            async fetch() {
                let [ wallets, list ] = await Promise.all([
                    payoutService.getWallets(),
                    payoutService.getSpecialPayout(),
                ]);
                this.wallets = wallets.data;
                this.csvRecords = list.data;

                if (this.csvRecords.length > 0) {
                    const key = this.csvRecords[0].from_wallet;
                    const wallet = this.wallets.filter(wallet => {
                        return wallet.key == key;
                    })[0];
                    this.selectedWallet = wallet;
                }
            },
            
            /** 取り消し */
            async cancel() {
                await payoutService.cancel();
                this.selectedWallet = null;
                this.csvRecords = [];
                this.formData.file = null;
                this.$refs.fileComponent.value = null;
            },

            /** CSVファイルを選択した */
            async handleOnChangeFileInput(e) {
                const files = e.target.files || e.dataTransfer.files;
                const file = files[0];

                // キャンセルボタンが押された時は何もしない
                if (file === undefined) return;

                this.formData.file = file;
                this.formData.fromWalletKey = this.selectedWallet.key;

                try {
                    // ファイルサイズが10Mを越えるときはアップロードしない
                    if (this.formData.file.size > 1024 * 1024) {
                        this.showErrMessage("FILE_SIZE_EXCEEDED");
                        // fileオブジェクトをクリアする
                        e.target.value = "";
                        this.formData.file = null;
                        return;
                    }

                    const result = await payoutService.postCsv(this.formData);
                    this.csvRecords = result.data;

                } catch (err) {
                    const errors = err.response.data.errors;
                    if (errors) {
                        if (Array.isArray(errors)) {
                            errors.forEach(errItem => {
                                if (errItem.field) {
                                    this.showErrMessage(errItem.message[0]);
                                }
                            })
                        } else {
                            alert(`CSVのアップロードに失敗しました。\n${errors}`)
                        }
                    } else {
                        alert("CSVのアップロードに失敗しました。");
                    }

                    // fileオブジェクトをクリアする
                    e.target.value = "";
                    this.formData.file = null;
                }
            },

            /** 論理層実行 */
            async executeLogicalLayer() {
                try {
                    const result = await payoutService.executeLogicalLayer();
                    this.csvRecords = result.data;

                    alert("論理層払い出し処理を実行しました。"); 
                } catch (err) {
                    const result = err.response.data;
                    alert(`論理層払い出し実行エラー\n${result.errors}`);
                }            
            },

            /** 物理層実行 */
            async executePhysicalLayer() {
                try {
                    const result = await payoutService.executePhysicalLayer();
                    alert("物理層払い出し処理を実行しました。"); 

                    this.selectedWallet = null;
                    this.csvRecords = [];
                    this.formData.file = null;
                    this.$refs.fileComponent.value = null;
                } catch (err) {
                    const result = err.response.data;
                    alert(`物理層払い出し実行エラー\n${result.errors}`);
                }            
            },

            showErrMessage(type) {
                const errMessage = this.$i18n.t('errors.' + type);
                alert(`CSVのアップロードに失敗しました。\n${errMessage}`);
            },
        },

        computed: {
            csvFileName: function() {
                if (this.formData.file == null) {
                    if (this.csvRecords.length > 0) {
                        return this.uploadedCsvFileName;
                    } else {
                        return "";
                    }
                } else {
                    return this.formData.file.name;
                }
            },
            csvNgRecords: function() {
                return this.csvRecords.filter(record => {
                    return ! record.verify_status;
                });
            },
            uploadedFromWalletLabel: function () {
                if (this.csvRecords.length == 0) {
                    return "";
                }

                const key = this.csvRecords[0].from_wallet;
                const wallet = this.wallets.filter(wallet => {
                    return wallet.key == key;
                })[0];
                return `${wallet.name}\n(${wallet.address})` ;
            },
            uploadedCsvFileName: function() {
                return this.csvRecords.length > 0 ? this.csvRecords[0].file_name : "" ;
            },
            allCount: function () {
                return this.csvRecords.length;
            },
            okCount: function () {
                return this.allCount - this.ngCount;
            },
            ngCount: function () {
                return this.csvNgRecords.length;
            },
            logicalLayerCount: function() {
                return this.csvRecords.filter(record => {
                    return record.logical_layer_success;
                }).length;
            },
            verifyResult: function() {
                return this.csvRecords.map(record => {
                    return { 'status'       : record.verify_status ? 'OK' : 'NG', 
                             'error'        : record.error_reason,
                             'email'        : record.email,
                             'force_club_id': record.force_club_id,
                             'address'      : record.address,
                             'amount'       : record.amount,
                             'remarks'      : record.remarks,
                             'type'         : record.type,
                            };
                })
            
            },
            enabledSelectWallet: function() {
                return ! this.csvFileName;
            },
            enabledSelectCsv: function() {
                return this.selectedWallet && !this.csvFileName;
            },
            enabledLogicalLayer: function() {
                return this.selectedWallet 
                    && this.csvFileName 
                    && this.ngCount == 0 
                    && this.logicalLayerCount == 0;
            },
            enabledPhysicalLayer: function() {
                return this.logicalLayerCount > 0 
                    && this.allCount == this.logicalLayerCount;
            },
            enabledCancel: function() {
                return this.selectedWallet != null;
            },
            enabledExportCsv: function() {
                return this.csvFileName.length > 0;
            }
        }
    }
</script>

<style>
    label, input, .btn {
        display: block;
    }
    table {
        width: 100%;
        white-space: nowrap;
    }
    .check-result {
        overflow: auto; 
        max-height: 60vh;
    }
    .file-btn {
        display: none;
    }
    .wallet-address, .file-name {
        overflow-wrap: anywhere;
    }
</style>