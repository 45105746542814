<template>
    <div>
        <div class="title">{{ $t('token.balances.list.title') }}</div>
        <table class="table table-striped">
            <thead>
            <tr>
                <th>{{ $t('token.balances.list.columns.wallet') }}</th>
                <th>{{ $t('token.balances.list.columns.user') }}</th>
                <th>{{ $t('token.balances.list.columns.value') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list">
                <td class="text-nowrap">{{item.wallet ? item.wallet.public : '-'}}</td>
                <td class="text-nowrap">{{item.user ? (item.user.full_name + ' (AUBE:'+ item.user.external_user_id +')') : '-'}}</td>
                <td class="text-nowrap">{{item.value}} {{item.contract.name}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import tokenService from './../../services/TokenService';

    export default {
        name: 'TokenBalancesList',

        data() {
            return {
                list: [],
                pagination: {
                    total_pages: 0,
                    current_page: 1,
                    per_page: 100,
                    total: 0,
                }
            }
        },

        created() {
            this.fetchBalances();
        },

        methods: {
            async fetchBalances() {
                const params = {
                    page: this.pagination.current_page,
                    size: this.pagination.per_page,
                };
                const data = (await tokenService.getBalances(params)).data;
                for (let i in data) {
                    if (data.hasOwnProperty(i) && i !== 'meta') {
                        this.list.push(data[i]);
                    }
                }
                this.pagination = data.meta.pagination;
            },
        }

    }
</script>

<style>

</style>
