<template>
    <div>
        <div class="title">{{ $t('payments.list.title') }}</div>
        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col">{{ $t('user.id') }}</th>
                <th scope="col">{{ $t('user.first') }}</th>
                <th scope="col">{{ $t('user.family') }}</th>
                <th scope="col">{{ $t('user.email') }}</th>
                <th scope="col">{{ $t('payments.id') }}</th>
                <th scope="col">{{ $t('payments.date') }}</th>
                <th scope="col">{{ $t('payments.amount') }}</th>
                <th scope="col">{{ $t('payments.status') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list">
                <td>{{ item.user ? item.user.id : '-' }}</td>
                <td>{{ item.user ? item.user.userData.first_name : '-' }}</td>
                <td>{{ item.user ? item.user.userData.last_name : '-' }}</td>
                <td>{{ item.user ? item.user.email : '-' }}</td>
                <td>{{ item.external_id }}</td>
                <td>{{ getDate(item.date) }}</td>
                <td>{{ item.amount }} {{ item.currency }}</td>
                <td>{{ $t(`payPal.transactions.statuses[${item.status}]`) }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import payPalService from "../services/PayPalService";
    import moment from 'moment';

    export default {
        name: 'PayPalTransactions',

        data() {
            return {
                list: [],
                pagination: {
                    total_pages: 0,
                    current_page: 1,
                    per_page: 100,
                    total: 0,
                },
            }
        },

        created() {
            this.fetchTransactions();
        },

        methods: {
            getDate(value) {
                return moment(String(value)).format('YYYY-MM-DD HH:mm')
            },

            async fetchTransactions() {
                const params = {
                    page: this.pagination.current_page,
                    size: this.pagination.per_page,
                };
                const data = (await payPalService.getTransactions(params)).data;
                for (let i in data) {
                    if (data.hasOwnProperty(i) && i !== 'meta') {
                        this.list.push(data[i]);
                    }
                }
                this.pagination = data.meta.pagination;
            },
        }
    }
</script>

<style>

</style>
